import React from "react";
import { Row, Col, Button, Checkbox, Form } from "antd";
import reports from "../../../../constants/reports";

export default class ReportTypes extends React.Component {
  render() {
    return (
      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item label="Report Types" name="report_type" rules={[{ required: true }]}>
            <Checkbox.Group
              className="card-checkbox report_type_checkboxes"
              disabled={this.props.loading}
            >
              <Row gutter={[16, 16]}>
                {Object.keys(reports).map((key) => {
                  return (
                    <Col span={12} key={key}>
                      <Checkbox value={key}>
                        <div>
                          <strong>{reports[key].description}</strong>
                        </div>
                        <div>{reports[key].purpose}</div>
                        <div>
                          <em>
                            <small>{key}</small>
                          </em>
                        </div>
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <Button
              onClick={() => this.props.changeIndex(1)}
              disabled={this.props.loading}
            >
              Back
            </Button>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                // block
                className="mt10"
                loading={this.props.loading}
              >
                {this.props.mode === "loading"
                  ? "Submit"
                  : this.props.mode === "edit"
                  ? "Update vendor details"
                  : "Add a vendor"}
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  }
}
