import React from "react";
import { Layout, Menu, Avatar, Button } from "antd";
import FullLogo from "../images/full_logo.gif";
import { UserContext } from "../contexts/user";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../constants/routes";

const { Sider } = Layout;
export default function Sidebar() {
  let currentPath = useLocation();
  const navigate = useNavigate();
  const menus = [
    {
      key: routes.VENDORS.path,
      icon: <i className="ri-user-3-line"></i>,
      label: "Vendors",
      requiredPermissions: [
        "create Vendor",
        "update Vendor",
        "view Vendor",
        "export Vendor",
      ],
    },
    {
      key: routes.PRODUCTS.path,
      icon: <i className="ri-file-list-2-line"></i>,
      label: "Products",
      requiredPermissions: ["view Product", "export Product", "fetch Product"],
    },
    {
      key: routes.REMOVAL_SHIPMENTS.path,
      icon: <i className="ri-truck-line"></i>,
      label: "Removal Shipment",
      requiredPermissions: ["view RemovalShipment", "export RemovalShipment"],
    },
    {
      key: routes.PRODUCT_RESEARCH.path,
      icon: <i className="ri-search-line"></i>,
      label: "Product Research",
      requiredPermissions: [
        "create ProductResearch",
        "update ProductResearch",
        "delete ProductResearch",
        "view ProductResearch",
        "export ProductResearch",
      ],
    },
    {
      key: routes.REPORT_REQUEST.path,
      icon: <i className="ri-file-list-3-line"></i>,
      label: "Report Request",
      requiredPermissions: ["view ReportRequest"],
    },

    {
      key: routes.USERS.path,
      icon: <i className="ri-group-line"></i>,
      label: "Users",
      requiredPermissions: [
        "view User",
        "create User",
        "update User",
        "delete User",
      ],
    },
    {
      key: routes.ACCESS_MANAGEMENT.path,
      icon: <i className="ri-key-line"></i>,
      label: "Access Control",
      requiredPermissions: [
        "create Role",
        "update Role",
        "delete Role",
        "view Role",
        "view Permission",
      ],
    },
   
  ];
  return (
    <UserContext.Consumer>
      {(userContext) => {
        return (
          <Sider theme="light" width={250}>
            <div className="sidebar-custom-wrap">
              <div>
                <img
                  src={FullLogo}
                  className="img-fluid sidebar-logo"
                  alt="TradePort"
                />
                <Menu
                  mode="inline"
                  selectedKeys={[currentPath.pathname]}
                  items={menus
                    .filter((item) => {
                      if (
                        item.requiredPermissions.filter(
                          (n) =>
                            userContext?.user?.permissions.indexOf(n) !== -1
                        ).length > 0
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    .map(({ requiredPermissions, ...rest }) => rest)}
                  onSelect={(item) => navigate(item.key)}
                />
              </div>
              <div>
                <div className="sidebar-user-card">
                  <div className="user-details-wrap">
                    <Avatar
                      style={{
                        backgroundColor: "#cfe2fd",
                        color: "#1f3f7d",
                      }}
                      size="small"
                    >
                     {userContext.user.name.charAt(0)}
                    </Avatar>
                    <div className="user-details">
                      <div className="user-details-name">
                        {userContext.user.name}
                      </div>
                      <div className="user-details-email">
                        {userContext.user.email}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button size="small" onClick={userContext.logout}>
                      <i className="ri-logout-circle-r-line"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Sider>
        );
      }}
    </UserContext.Consumer>
  );
}
