import React from "react";
import PageHeader from "../../components/pageHeader";
import { Button, Row, Col, Input, Table, Space } from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import ColumnSearch from "../../components/columnSearch";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class RemovalShipment extends React.Component {
  state = {
    shipment: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      vendor_code: null,
      vendor_name: null,
    },
  };
  vendorIdFilter = React.createRef();
  orderIdFilter = React.createRef();
  trackingNumberFilter = React.createRef();
  orderDateFilter = React.createRef();
  vendorNameFilter = React.createRef();
  skuFilter = React.createRef();
  fnskuFilter = React.createRef();
  carrierFilter = React.createRef();
  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (
        columnName === "shipment_date" &&
        this.state.filters[columnName] !== null
      ) {
        dataToSend = {
          ...dataToSend,
          shipment_date_start:
            this.state.filters[columnName][0]?.format("YYYY-MM-DD"),
          shipment_date_end:
            this.state.filters[columnName][1]?.format("YYYY-MM-DD"),
        };
      } else if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getRemovalShipment = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({ shipment: { ...this.state.shipment, loading: true } });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(
        endpoints.removal_shipment_list,
        dataToSend
      );
      if (response.status === "Success") {
        this.setState({
          shipment: {
            ...this.state.shipment,
            data: response.data.data,
            vendor: response.data.data?.vendor,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
      }
    } catch (e) {
      this.setState({
        shipment: { ...this.state.shipment, loading: false, data: [] },
      });
    }
  };

  exportShipment = async () => {
    const api = requests();
    this.setState({ shipment: { ...this.state.shipment, loading: true } });
    try {
      let dataToSend = this.generateApiParameters();
      const response = await api.get(endpoints.export_shipment, dataToSend);
      downloadCSV(
        response,
        ["Removal-shipment", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        shipment: { ...this.state.shipment, loading: false },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view RemovalShipment")) {
      this.getRemovalShipment();
    }
  }

  render() {
    const columns = [
      {
        title: "Vendor Code",
        dataIndex: "vendor.vendor_code",
        key: "vendor_code",
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.vendor_code || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.vendorIdFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (_, record) => record?.vendor?.vendor_code,
      },
      // {
      //   title: "Vendor Name",
      //   key: "vendor_name",
      //   dataIndex: "vendor_name",
      //   // sorter: {
      //   //   compare: () => true,
      //   //   multiple: 1,
      //   // },
      //   filteredValue: this.state.filters.vendor_name || null,
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //     close,
      //   }) => {
      //     return (
      //       <ColumnSearch
      //         ref={this.vendorNameFilter}
      //         onSearch={(value) => {
      //           setSelectedKeys(value ? [value] : []);
      //           confirm();
      //         }}
      //       />
      //     );
      //   },
      //   render: (_, record) => record?.vendor?.vendor_name,
      // },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        // showSorterTooltip: false,
        // sorter: {
        //   compare: () => true,
        //   multiple: 1,
        // },
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.order_id || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.orderIdFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Shipment Date",
        key: "shipment_date",
        dataIndex: "shipment_date",
        // render: (shipment_date) => moment(shipment_date).format("DD-MM-YYYY"),
        // sorter: {
        //   compare: () => true,
        //   multiple: 2,
        // },
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.shipment_date || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.orderDateFilter}
              type="dateRange"
              onSearch={(value) => {
                setSelectedKeys(value ? value : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        showSorterTooltip: true,
        sorter: true,
        filteredValue: this.state.filters.sku || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.skuFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "FNSKU",
        dataIndex: "fnsku",
        key: "fnsku",
        showSorterTooltip: true,
        sorter: true,
        filteredValue: this.state.filters.fnsku || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.fnskuFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Carrier",
        dataIndex: "carrier",
        key: "carrier",
        // sorter: {
        //   compare: () => true,
        //   multiple: 3,
        // },
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.carrier || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.carrierFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Tracking No",
        dataIndex: "tracking_number",
        key: "tracking_number",
        // showSorterTooltip: false,
        // sorter: {
        //   compare: () => true,
        //   multiple: 4,
        // },
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.tracking_number || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.trackingNumberFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Created At",
        key: "created_at",
        dataIndex: "created_at",
        // render: (created_at) =>
        //   moment(created_at).format("DD-MM-YYYY HH:mm:ss"),
        // sorter: {
        //   compare: () => true,
        //   multiple: 1,
        // },
        showSorterTooltip: false,
        sorter: true,
      },
      {
        title: "Last Modified At",
        key: "last_modified_at",
        dataIndex: "last_modified_at",
        // render: (last_modified_at) =>
        //   moment(last_modified_at).format("DD-MM-YYYY HH:mm:ss"),
        // sorter: {
        //   compare: () => true,
        //   multiple: 1,
        // },
        showSorterTooltip: false,
        sorter: true,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              <AuthChecker permissions={["view RemovalShipment"]}>
                <Link to={routes.VIEW_SHIPMENT.path.replace(":id", record.id)}>
                  <Button>View</Button>
                </Link>
              </AuthChecker>
              {/* <Link to={routes.EDIT_VENDOR.path.replace(":id", record.id)}>
                <Button>Edit</Button>
              </Link> */}
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <PageHeader
          title="Removal Shipment"
          actions={[
            <AuthChecker permissions={["view RemovalShipment"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getRemovalShipment();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getRemovalShipment();
                    }
                  }}
                  disabled={this.state.shipment.loading}
                />
                <Button
                  onClick={() => this.getRemovalShipment()}
                  loading={this.state.shipment.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            <AuthChecker permissions={["export RemovalShipment"]}>
              <Button
                icon={<i className="ri-download-cloud-2-line"></i>}
                loading={this.state.shipment.loading}
                onClick={() => this.exportShipment()}
              >
                Export
              </Button>
            </AuthChecker>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName]
                                .map((el) =>
                                  columnName === "shipment_date"
                                    ? el.format("YYYY-MM-DD")
                                    : el
                                )
                                .join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getRemovalShipment()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.vendorIdFilter?.current?.reset();
                        this.orderIdFilter?.current?.reset();
                        this.trackingNumberFilter?.current?.reset();
                        this.orderDateFilter?.current?.reset();
                        this.vendorNameFilter?.current?.reset();
                        this.skuFilter?.current?.reset();
                        this.fnskuFilter?.current?.reset();
                        this.carrierFilter?.current?.reset();
                        this.setState({ filters: modifiedObj }, () =>
                          this.getRemovalShipment()
                        );
                      }}
                      loading={this.state.shipment.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view RemovalShipment"]}>
              <Table
                columns={columns}
                loading={this.state.shipment.loading}
                dataSource={this.state.shipment.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                            .filter((col) => col.order !== undefined)
                            .map((sortItem) => {
                              return {
                                column: sortItem.field,
                                order: sortItem.order,
                              };
                            })
                        : Object.keys(sorter).length > 0
                        ? sorter.order !== undefined
                          ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                          : []
                        : [],
                      filters: filters,
                    },
                    () => this.getRemovalShipment()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
      </>
    );
  }
}
RemovalShipment.contextType = UserContext;
export default RemovalShipment;
