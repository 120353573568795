import React from "react";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import PageHeader from "../../components/pageHeader";
import { Row, Spin, Col, Alert, Button } from "antd";
import DetailBlock from "../../components/detailBlock";
import ReactCountryFlag from "react-country-flag";
import reportTypes from "../../constants/reports";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthChecker from "../../components/authChecker";

class ViewVendor extends React.Component {
  state = {
    loading: false,
    vendorData: null,
  };
  getVendorData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_vendor, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ loading: false, vendorData: response.data });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.getVendorData(this.props.params.id);
    } else {
    }
  }

  render() {
    return (
      <>
        <AuthChecker permissions={["view Vendor"]} redirectTo={-1}>
          <PageHeader
            title="Vendor Details"
            actions={[
              <AuthChecker permissions={["view Vendor"]}>
                <Link to={routes.VENDORS.path}>
                  <Button disabled={this.state.loading}>Cancel</Button>
                </Link>
              </AuthChecker>,
              <AuthChecker permissions={["update Vendor"]}>
                <Link
                  to={routes.EDIT_VENDOR.path.replace(
                    ":id",
                    this.props.params.id
                  )}
                >
                  <Button type="primary" disabled={this.state.loading}>
                    Edit
                  </Button>
                </Link>
              </AuthChecker>,
            ]}
          />
          <Spin spinning={this.state.loading}>
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
              <Col span={10}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <DetailBlock
                      label="ID"
                      value={this.state.vendorData?.id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Email Address"
                      value={this.state.vendorData?.email}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Password"
                      // value={this.state.vendorData?.password}
                      value={"********"}
                      loading={this.state.loading}
                    />
                  </Col>
                  {/* <Col span={24}>
                  <DetailBlock
                    label="Email Address"
                    value={this.state.vendorData?.email}
                    loading={this.state.loading}
                  />
                </Col> */}
                  {/* <Col span={8}>
                  <DetailBlock
                    label="Account Type"
                    value={this.state.vendorData?.account_type}
                    loading={this.state.loading}
                  />
                </Col> */}
                  <Col span={12}>
                    <DetailBlock
                      label="Vendor Name"
                      value={this.state.vendorData?.vendor_name}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Vendor Code"
                      value={this.state.vendorData?.vendor_code}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Seller Partner ID"
                      value={this.state.vendorData?.seller_partner_id}
                      loading={this.state.loading}
                    />
                  </Col>

                  <Col span={24}>
                    <DetailBlock
                      label="MWS Token"
                      value={this.state.vendorData?.mws_token}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Status"
                      value={
                        this.state.vendorData?.status === "ACTIVE" ? (
                          <Alert
                            message={this.state.vendorData?.status}
                            type="success"
                            showIcon
                          />
                        ) : (
                          <Alert
                            message={this.state.vendorData?.status}
                            type="error"
                            showIcon
                          />
                        )
                      }
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Amazon Status"
                      value={
                        this.state.vendorData?.amazon_status ===
                          "AUTHORIZE_PENDING" ? (
                          <Alert
                            type="warning"
                            showIcon
                            message={this.state.vendorData?.amazon_status}
                          />
                        ) : this.state.vendorData?.amazon_status ===
                          "AUTHORIZED" ? (
                          <Alert
                            type="success"
                            showIcon
                            message={this.state.vendorData?.amazon_status}
                          />
                        ) : this.state.vendorData?.amazon_status ===
                          "UN_AUTHORIZE" ? (
                          <Alert
                            type="error"
                            showIcon
                            message={this.state.vendorData?.amazon_status}
                          />
                        ) : (
                          ""
                        )
                      }
                      loading={this.state.loading}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <DetailBlock
                  label="Marketplace Identifiers"
                  value={
                    this.state.vendorData?.marketplace_identifiers && this.state.vendorData.marketplace_identifiers.length > 0 ? (
                      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                        {this.state.vendorData.marketplace_identifiers.map((marketplace, index) => {
                          return (
                            <div className="marketplace-detail-card" key={index}>
                              <div className="d-flex align-items-center">
                                <ReactCountryFlag
                                  countryCode={
                                    marketplace.country_code === "UK" ? "gb" : marketplace.country_code
                                  }
                                  style={{ fontSize: 32, marginRight: 8 }}
                                />
                                <div>
                                  <strong>{marketplace.country}</strong>
                                  <div>
                                    <small className="marketplace-region">{marketplace.region}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    ) : (
                      <span>Not Available</span>
                    )
                  }
                  loading={this.state.loading}
                />
              </Col>

              <Col span={9}>
                <DetailBlock
                  label="Report Types"
                  value={this.state.vendorData?.report_type.map(
                    (report, index) => {
                      return (
                        <div className="report-type-wrapper" key={index}>
                          <div>
                            <strong>
                              {reportTypes[report.report_type].description}
                            </strong>
                          </div>
                          <div>{reportTypes[report.report_type].purpose}</div>
                          <div>
                            <em>
                              <small>{report.report_type}</small>
                            </em>
                          </div>
                        </div>
                      );
                    }
                  )}
                  loading={this.state.loading}
                />
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}

export default withRouter(ViewVendor);
