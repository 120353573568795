import React from "react";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import PageHeader from "../../components/pageHeader";
import { Row, Spin, Col, Alert, Button } from "antd";
import DetailBlock from "../../components/detailBlock";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthChecker from "../../components/authChecker";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";

class ShowResearch extends React.Component {
  state = {
    loading: false,
    reserchData: null,
    loadingBtn: false,
  };
  getReserchData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_research, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ loading: false, reserchData: response.data });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.getReserchData(this.props.params.id);
    } else {
    }
  }
  getProductResearch = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch, loading: true },
    });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(
        endpoints.product_research_list,
        dataToSend
      );
      if (response.status === "Success") {
        this.setState({
          productResearch: {
            ...this.state.productResearch,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
          responseStatus: 200,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        productResearch: {
          ...this.state.productResearch,
          loading: false,
          data: [],
        },
        responseStatus: 500,
      });
    }
  };

  exportCSV = async (id) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch },
      loadingBtn: true,
    });
    try {
      // let dataToSend = this.generateApiParameters();
      const response = await api.get(
        endpoints.product_download_csv + "/" + id
      );
      downloadCSV(
        response,
        ["productResearch", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        productResearch: { ...this.state.productResearch },
        loadingBtn: false
      });
    }
  };

  exportDestinationCSV = async (id) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch },
      loadingBtn: true
    });
    try {
      const response = await api.get(
        endpoints.product_destination_csv + "/" + id
      );
      downloadCSV(
        response,
        ["productResearch", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        productResearch: { ...this.state.productResearch },
        loadingBtn: false
      });
    }
  };

  render() {
    return (
      <>
        <AuthChecker permissions={["view Vendor"]} redirectTo={-1}>
          <PageHeader
            title="Product Research Details"
            actions={[
              <AuthChecker permissions={["view Vendor"]}>
                <Link to={routes.PRODUCT_RESEARCH.path}>
                  <Button disabled={this.state.loading}>Cancel</Button>
                </Link>
              </AuthChecker>,
            ]}
          />
          <Spin spinning={this.state.loading}>
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
              <Col span={10}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <DetailBlock
                      label="ID"
                      value={this.state.reserchData?.id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Username"
                      value={this.state.reserchData?.name}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Status"
                      value={<Alert message={this.state.reserchData?.status} type="success" />}
                      loading={this.state.loading}
                    />
                  </Col>
                  {this.state.reserchData?.source_data ? (
                  <Col span={12}>
                    <Button
                      icon={<i className="ri-download-cloud-2-line"></i>}
                      loading={this.state.loadingBtn}
                      onClick={() => this.exportCSV(this.state.reserchData?.id)}
                    >
                      Get Source CSV
                    </Button>
                  </Col>
                  ) : null}
                  {this.state.reserchData?.destination_data ? (
                    <Col span={12}>
                      <Button
                        icon={<i className="ri-download-cloud-2-line"></i>}
                        loading={this.state.loadingBtn}
                        onClick={() => this.exportDestinationCSV(this.state.reserchData?.id)}
                      >
                        Get Destination CSV
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}

export default withRouter(ShowResearch);
