import React from "react";
import PageHeader from "../../components/pageHeader";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import ColumnSearch from "../../components/columnSearch";
import {
  Input,
  Button,
  Row,
  Col,
  Table,
  Image,
  Typography,
  Space,
  Modal,
  Select,
  Empty,
  Spin,
  Alert,
} from "antd";
import AuthChecker from "../../components/authChecker";
import { UserContext } from "../../contexts/user";
import notAvailableImage from "../../images/no-image-available.jpg";

class Products extends React.Component {
  state = {
    products: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      product_type: null,
      marketplace_id: null,
      brand: null,
      manufacturer: null,
    },
    amazonModalOpen: false,
    amazonModal: {
      selectedIdentifier: "asin",
      data: [],
      loading: false,
      searchValue: null,
      isError: false,
      isASINSuccess: false,
    },
  };
  productTypeFilter = React.createRef();
  marketPlaceIdFilter = React.createRef();
  brandFilter = React.createRef();
  manufacturerFilter = React.createRef();
  identifierFilter = React.createRef();
  itemNameFilter = React.createRef();
  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getProducts = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({ products: { ...this.state.products, loading: true } });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(endpoints.products_list, dataToSend);
      if (response.status === "Success") {
        this.setState({
          products: {
            ...this.state.products,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
      }
    } catch (e) {
      this.setState({
        products: { ...this.state.products, loading: false, data: [] },
      });
    }
  };

  exportProducts = async () => {
    const api = requests();
    this.setState({ products: { ...this.state.products, loading: true } });
    try {
      let dataToSend = this.generateApiParameters();
      const response = await api.get(endpoints.export_product, dataToSend);
      downloadCSV(
        response,
        ["products", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
    } finally {
      this.setState({
        products: { ...this.state.products, loading: false },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view Product")) {
      this.getProducts();
    }
  }

  searchOnAmazon = async () => {
    this.setState(
      {
        amazonModal: {
          ...this.state.amazonModal,
          loading: true,
          isError: false,
          isASINSuccess: false,
        },
      },
      async () => {
        try {
          const api = requests();
          const response = await api.get(
            "/search-product/" +
            this.state.amazonModal.searchValue +
            "/" +
            this.state.amazonModal.selectedIdentifier.toUpperCase()
          );
          let result = response?.data;
          this.setState({
            amazonModal: {
              ...this.state.amazonModal,
              data: result,
              loading: false,
            },
          });
          // let result = [
          //   {
          //     asin: "B00006B7P6",
          //     images: [
          //       "https://m.media-amazon.com/images/I/51AO2i1JPwL.jpg",
          //       "https://m.media-amazon.com/images/I/51AO2i1JPwL._SL75_.jpg",
          //       "https://m.media-amazon.com/images/I/916an+Y98OL.jpg",
          //     ],
          //   },
          //   {
          //     asin: "B0752VNRSF",
          //     images: [
          //       "https://m.media-amazon.com/images/I/41L+6kOvzzL.jpg",
          //       "https://m.media-amazon.com/images/I/41L+6kOvzzL._SL75_.jpg",
          //       "https://m.media-amazon.com/images/I/51Yqe27n8uL.jpg",
          //     ],
          //   },
          // ];
          // setTimeout(() => {
          //   this.setState({
          //     amazonModal: {
          //       ...this.state.amazonModal,
          //       data: result,
          //       loading: false,
          //     },
          //   });
          // }, 1000);
        } catch (e) {
          this.setState({
            amazonModal: {
              ...this.state.amazonModal,
              isError: true,
              loading: false,
            },
          });
        }
      }
    );
  };

  fetchProdcut = async (asin) => {
    this.setState({
      amazonModal: { ...this.state.amazonModal, loading: true, isError: false },
    });
    try {
      const api = requests();
      await api.get(
        "/fetch-product/" +
        this.state.amazonModal.searchValue +
        "/" +
        this.state.amazonModal.selectedIdentifier.toUpperCase() +
        "/" +
        asin
      );

      this.setState({
        amazonModal: {
          ...this.state.amazonModal,
          loading: false,
          isASINSuccess: true,
        },
      });
    } catch (e) {
      this.setState({
        amazonModal: {
          ...this.state.amazonModal,
          isError: true,
          loading: false,
        },
      });
    }
  };

  render() {
    const columns = [
      {
        title: "Product",
        key: "item_name",
        dataIndex: "item_name",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 1,
        },
        filteredValue: this.state.filters.item_name || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.itemNameFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text, record, index) => {
          let highestWidthImage = null;
          let lowestWidthImage = null;

          try {
            const imagesArray = JSON.parse(record.images);

            if (Array.isArray(imagesArray)) {
              const mainImages = imagesArray.filter(
                (image) => image.variant.toLowerCase() === "main"
              );

              // Check if mainImages is an array and contains elements
              if (Array.isArray(mainImages) && mainImages.length > 0) {
                // Find the image with the highest width
                highestWidthImage = mainImages.reduce((prev, current) => {
                  return prev.width > current.width ? prev : current;
                });

                // Find the image with the lowest width
                lowestWidthImage = mainImages.reduce((prev, current) => {
                  return prev.width < current.width ? prev : current;
                });
              }
            }
          } catch (error) {
            console.error("Error parsing productData.images:", error);
          }
          return (
            <React.Fragment key="index">
              <div className="product-image-container">
                <div className="product-image-wrapper">
                  {highestWidthImage && lowestWidthImage ? (
                    <Image width={84} height={84} src={lowestWidthImage.link}
                      preview={{
                        src: highestWidthImage.link,
                      }}
                      fallback={notAvailableImage}
                    />
                  ) : (
                    <Image width={84} height={84} src={notAvailableImage} />
                  )}
                </div>
                <div>
                  <div className="product-title">
                    <Typography.Text
                      style={{ width: 200 }}
                      ellipsis={{ tooltip: record.item_name }}
                    >
                      {record.item_name}
                    </Typography.Text>
                  </div>
                  <strong className="product-price">
                    {record.new_landed_price_currency}{" "}
                    {record.new_landed_price_amount}
                  </strong>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Identifier",
        key: "identifier",
        dataIndex: "identifier",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 2,
        },
        filteredValue: this.state.filters.identifier || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.identifierFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        // render: (_, record) => <Tag>{record.identifier}</Tag>,
      },
      {
        title: "Type",
        key: "product_type",
        dataIndex: "product_type",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 3,
        },
        filteredValue: this.state.filters.product_type || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.productTypeFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 150 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Marketplace",
        key: "marketplace_id",
        dataIndex: "marketplace_id",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 4,
        },
        filteredValue: this.state.filters.marketplace_id || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.marketPlaceIdFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Brand",
        key: "brand",
        dataIndex: "brand",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 5,
        },
        filteredValue: this.state.filters.brand || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.BrandFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 100 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Manufacturer",
        key: "manufacturer",
        dataIndex: "manufacturer",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 6,
        },
        filteredValue: this.state.filters.manufacturer || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.ManufacturerFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 100 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Model No",
        key: "model_number",
        dataIndex: "model_number",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 7,
        },
        filteredValue: this.state.filters.model_number || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.ManufacturerFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Source Type",
        key: "source_type",
        dataIndex: "source_type",
        showSorterTooltip: true,
        sorter: {
          compare: () => true,
          multiple: 6,
        },
        filteredValue: this.state.filters.source_type || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.ManufacturerFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 100 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Source Identifier",
        key: "source_identifier",
        dataIndex: "source_identifier",
        showSorterTooltip: true,
        sorter: {
          compare: () => true,
          multiple: 6,
        },
        filteredValue: this.state.filters.source_identifier || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.ManufacturerFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 100 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              <Link
                to={routes.VIEW_PRODUCT.path.replace(":id", record.identifier)}
              >
                <Button>View</Button>
              </Link>
              {/* <Link to={routes.EDIT_VENDOR.path.replace(":id", record.id)}>
                <Button>Edit</Button>
              </Link> */}
            </Space>
          );
        },
      },
    ];


    return (
      <>
        <PageHeader
          title="Products"
          actions={[
            <AuthChecker permissions={["view Product"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  allowClear
                  // value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getProducts();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getProducts();
                    }
                  }}
                  disabled={this.state.products.loading}
                />
                <Button
                  onClick={() => this.getProducts()}
                  loading={this.state.products.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            <AuthChecker permissions={["export Product"]}>
              <Button
                icon={<i className="ri-download-cloud-2-line"></i>}
                loading={this.state.products.loading}
                onClick={() => this.exportProducts()}
              >
                Export
              </Button>
            </AuthChecker>,
            <AuthChecker permissions={["fetch Product"]}>
              <Button
                onClick={() =>
                  this.setState({
                    amazonModalOpen: true,
                    amazonModal: {
                      ...this.state.amazonModal,
                      data: [],
                      isError: false,
                      isASINSuccess: false,
                      searchValue: null,
                    },
                  })
                }
              >
                Direct search on{" "}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
                  alt="Amazon"
                  style={{ height: 20, marginLeft: 5, marginBottom: -10 }}
                />
              </Button>
            </AuthChecker>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName].join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getProducts()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        this.itemNameFilter?.current?.reset();
                        this.identifierFilter?.current?.reset();
                        this.productTypeFilter?.current?.reset();
                        this.marketPlaceIdFilter?.current?.reset();
                        this.brandFilter?.current?.reset();
                        this.manufacturerFilter?.current?.reset();

                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.setState({ filters: modifiedObj }, () =>
                          this.getProducts()
                        );
                      }}
                      loading={this.state.products.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view Product"]}>
              <Table
                columns={columns}
                loading={this.state.products.loading}
                dataSource={this.state.products.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                          .filter((col) => col.order !== undefined)
                          .map((sortItem) => {
                            return {
                              column: sortItem.field,
                              order: sortItem.order,
                            };
                          })
                        : Object.keys(sorter).length > 0
                          ? sorter.order !== undefined
                            ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                            : []
                          : [],
                      filters: filters,
                    },
                    () => this.getProducts()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
        <Modal
          title="Direct Search on Amazon"
          open={this.state.amazonModalOpen}
          maskClosable={false}
          onCancel={() => this.setState({ amazonModalOpen: false })}
          cancelButtonProps={{
            disabled: this.state.amazonModal.loading,
          }}
          closable={!this.state.amazonModal.loading}
          onOk={() => this.setState({ amazonModalOpen: false })}
          okButtonProps={{
            disabled: this.state.amazonModal.loading,
          }}
        >
          <Input
            className="amazon-serach-modal-search"
            addonBefore={
              <Select
                value={this.state.amazonModal.selectedIdentifier}
                disabled={this.state.amazonModal.loading}
                onChange={(value) =>
                  this.setState({
                    amazonModal: {
                      ...this.state.amazonModal,
                      selectedIdentifier: value,
                    },
                  })
                }
                options={[
                  {
                    label: "ASIN",
                    value: "asin",
                  },
                  {
                    label: "UPC",
                    value: "upc",
                  },
                  {
                    label: "EAN",
                    value: "ean",
                  },
                ]}
              />
            }
            disabled={this.state.amazonModal.loading}
            addonAfter={
              <Button
                loading={this.state.amazonModal.loading}
                onClick={() => this.searchOnAmazon()}
              >
                Search
              </Button>
            }
            onChange={(e) => {
              this.setState({
                amazonModal: {
                  ...this.state.amazonModal,
                  searchValue: e.target.value ?? null,
                },
              });
            }}
            value={this.state.amazonModal.searchValue}
          />
          <div className="mt30">
            {this.state.amazonModal.loading ? (
              <div className="text-center">
                <Spin spinning={this.state.amazonModal.loading} />
              </div>
            ) : this.state.amazonModal.isError ? (
              <Alert message="No Results Found" type="error" showIcon />
            ) : this.state.amazonModal.data?.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : this.state.amazonModal.isASINSuccess === true ? (
              <Alert
                message="The product has been successfully fetched, it can be searched in the product list after some time"
                type="success"
                showIcon
              />
            ) : (
              <Table
                size="small"
                columns={[
                  {
                    title: "Identifier",
                    key: "identifier",
                    dataIndex: "identifier",
                    render: (value, record, index) => {
                      return (
                        <>
                          <div className="product-image-container">
                            <div className="product-image-wrapper-sm">
                              <Image
                                width={40}
                                height={40}
                                src={record.images[0] ?? null}
                              />
                            </div>
                            <div>
                              <div className="product-title">
                                <Typography.Text
                                  style={{ width: 200 }}
                                  ellipsis={{ tooltip: record.item_name }}
                                >
                                  {record.asin}
                                </Typography.Text>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: "Action",
                    key: "action",
                    dataIndex: "action",
                    align: "right",
                    render: (_, record) => {
                      return (
                        <Button
                          size="small"
                          onClick={() => this.fetchProdcut(record.asin)}
                        >
                          Fetch
                        </Button>
                      );
                    },
                  },
                ]}
                dataSource={this.state.amazonModal.data}
                pagination={false}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

Products.contextType = UserContext;
export default Products;
