import { Row, Col, Form, Steps, message, Skeleton, Spin } from "antd";
import React from "react";
import PageHeader from "../../../components/pageHeader";
import endpoints from "../../../constants/endpoints";
import routes from "../../../constants/routes";
import BasicFields from "./parts/basicFields";
import MarketPlace from "./parts/marketplace";
import ReportTypes from "./parts/reportTypes";
import requests from "../../../utilities/api";
import withRouter from "../../../utilities/withRouter";
import AuthChecker from "../../../components/authChecker";
import { UserContext } from "../../../contexts/user";

class AddVendor extends React.Component {
  state = {
    mode: "loading",
    loading: false,
    activeStep: 0,
  };
  basicFormRef = React.createRef();
  marketplaceRef = React.createRef();
  reportsTypeRef = React.createRef();
  addVendor = async (values) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = null;
      if (this.state.mode === "add") {
        response = await api.post(endpoints.add_vendor, values, true);
      }
      if (this.state.mode === "edit") {
        response = await api.put(
          [endpoints.update_vendor, this.props.params.id].join("/"),
          values,
          true
        );
      }
      if (response.status === "Success") {
        message.success(response.message);
        this.props.navigate(routes.VENDORS.path);
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  getVendorData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_vendor, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        let { report_type, marketplace_identifiers, ...rest } = response.data;

        let emailNotificationArray = [];
        if (response.data.email_notification) {
          emailNotificationArray = response.data.email_notification.split(', ');
        }

        this.basicFormRef.current.setFieldsValue({ ...rest, email_notification: emailNotificationArray });
        let marketplace_identifier_ids =
          response.data.marketplace_identifiers.map((x) => x.id);
        this.marketplaceRef.current.setFieldsValue({
          marketplace_identifier_ids,
        });
        let reportTypes = report_type.map((x) => x.report_type);
        this.reportsTypeRef.current.setFieldsValue({
          report_type: reportTypes,
        });
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.setState({ mode: "edit" });
      if (this.context.user?.permissions.includes("update Vendor")) {
        this.getVendorData(this.props.params.id);
      }
    } else {
      this.setState({ mode: "add" });
    }
  }
  render() {
    return (
      <>
        <AuthChecker
          permissions={
            this.state.mode === "loading"
              ? ["create Vendor", "update Vendor"]
              : this.state.mode === "edit"
                ? ["update Vendor"]
                : ["create Vendor"]
          }
          redirectTo={-1}
        >
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <div style={{ position: "sticky", top: 40 }}>
                <PageHeader
                  title={
                    this.state.mode === "loading" ? (
                      <Skeleton.Input active={true} />
                    ) : this.state.mode === "edit" ? (
                      "Edit a vendor"
                    ) : (
                      "Add a vendor"
                    )
                  }
                />
                <Steps
                  direction="vertical"
                  current={this.state.activeStep}
                  items={[
                    {
                      title: "Basic Information",
                      description:
                        "Credentials and other information of the Vendor",
                    },
                    {
                      title: "Marketplace",
                      description:
                        "Select applicable marketplaace for the vendor",
                    },
                    {
                      title: "Report Type",
                      description: "Select report types for the vendor",
                    },
                  ]}
                />
              </div>
            </Col>
            <Col span={this.state.activeStep === 0 ? 11 : 16}>
              <Spin spinning={this.state.loading}>
                <Form.Provider
                  onFormFinish={(formName, { values, forms }) => {
                    if (formName === "basic") {
                      this.setState({ activeStep: 1 });
                    } else if (formName === "marketplaces") {
                      this.setState({ activeStep: 2 });
                    } else if (formName === "report_type") {
                      let valuesToSend = {};
                      forms.basic
                        .validateFields()
                        .then((basicFormValues) => {
                          forms.marketplaces
                            .validateFields()
                            .then((marketplaceValues) => {
                              valuesToSend = {
                                ...basicFormValues,
                                ...marketplaceValues,
                                ...values,
                                refresh_tokens: "NEED_TO_GENERATE",
                              };
                              this.addVendor(valuesToSend);
                            });
                        })
                        .catch(() => {
                          this.setState({ activeStep: 0 });
                        });
                    }
                  }}
                >
                  <Form
                    ref={this.basicFormRef}
                    name="basic"
                    layout="vertical"
                    className={[
                      "mt30",
                      this.state.activeStep === 0 ? "d-block" : "d-none",
                    ]}
                    requiredMark="optional"
                  >
                    <BasicFields loading={this.state.loading} />
                  </Form>
                  <Form
                    ref={this.marketplaceRef}
                    name="marketplaces"
                    layout="vertical"
                    className={
                      this.state.activeStep === 1 ? "d-block" : "d-none"
                    }
                    requiredMark="optional"
                  >
                    <MarketPlace
                      loading={this.state.loading}
                      changeIndex={(index) =>
                        this.setState({ activeStep: index })
                      }
                    />
                  </Form>
                  <Form
                    ref={this.reportsTypeRef}
                    name="report_type"
                    layout="vertical"
                    className={
                      this.state.activeStep === 2 ? "d-block" : "d-none"
                    }
                    requiredMark="optional"
                  >
                    <ReportTypes
                      mode={this.state.mode}
                      loading={this.state.loading}
                      changeIndex={(index) =>
                        this.setState({ activeStep: index })
                      }
                    />
                  </Form>
                </Form.Provider>
              </Spin>
            </Col>
          </Row>
        </AuthChecker>
      </>
    );
  }
}
AddVendor.contextType = UserContext;
export default withRouter(AddVendor);
