import React from "react";
import PageHeader from "../../components/pageHeader";
import { Row, Col, Form, Input, Button, message, Spin, Upload } from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import routes from "../../constants/routes";
import { UserContext } from "../../contexts/user";
import { UploadOutlined } from "@ant-design/icons";
import AuthChecker from "../../components/authChecker";

class AddResearch extends React.Component {
  state = {
    mode: "add",
    loading: false,
    roles: {
      loading: false,
      data: [],
    },
    user: {
      loading: false,
      data: {},
    },
    fileList: [], // Add fileList property here
  };

  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.setState({ mode: "edit" });
      if (this.context.user?.permissions.includes("update ProductResearch")) {
        this.getResearch();
      }
    } else {
      this.setState({ mode: "add" });
    }
  }
  formRef = React.createRef();
  getResearch = async () => {
    let api = requests();
    this.setState({
      loading: true,
      user: { ...this.state.user, loading: true },
    });
    try {
      let response = await api.get(endpoints.add_research, {}, true);
      this.setState({
        loading: false,
        user: {
          ...this.state.user,
          data: response.data,
          loading: false,
        },
      });
      this.formRef?.current?.setFieldsValue({
        name: response?.data?.name,
        source_data: response?.data?.source_data,
      });
    } catch (e) {
      this.setState({
        user: { ...this.state.user, loading: false },
        loading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    let api = requests();
    this.setState({ loading: true });

    try {
      let formData = new FormData();
      // console.log(values);
      formData.append("name", values.name);
      formData.append("source_data", values.source_data[0].originFileObj);
      // values.source_data = values.source_data[0];
      let response = await api.post(endpoints.add_research, formData, true);
      message.success(response.message);
      this.props.navigate(routes.PRODUCT_RESEARCH.path);
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleBeforeUpload = (file) => {
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("You can only upload CSV files!");
    }
    return isCSV ? true : Upload.LIST_IGNORE;
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  render() {
    return (
      <>
        <AuthChecker
          permissions={
            this.state.mode === "loading"
              ? ["create ProductResearch", "update ProductResearch"]
              : this.state.mode === "edit"
              ? ["update ProductResearch"]
              : ["create ProductResearch"]
          }
          redirectTo={-1}
        >
          <Spin spinning={this.state.loading}>
            <Row>
              <Col span={8} offset={8}>
                <PageHeader
                  title={
                    <div className="d-flex align-items-center">
                      <Button
                        type="text"
                        size="small"
                        style={{ width: 35, height: 35, marginRight: 5 }}
                        onClick={() => this.props.navigate(-1)}
                      >
                        <i
                          className="ri-arrow-left-line"
                          style={{ fontSize: 20, marginRight: 0 }}
                        ></i>
                      </Button>
                      Add a Product Research
                    </div>
                  }
                />
                <Form
                  layout="vertical"
                  onFinish={this.handleSubmit}
                  ref={this.formRef}
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="source_data"
                    label="Upload CSV"
                    getValueFromEvent={this.normFile}
                    valuePropName="fileList"
                    rules={[{ required: true }]}
                  >
                    <Upload
                      name="logo"
                      onRemove={(file) => {
                        const index = this.state.fileList.indexOf(file);
                        const newFileList = this.state.fileList.slice();
                        newFileList.splice(index, 1);
                        this.setState({ fileList: newFileList });
                      }}
                      beforeUpload={(file) => {
                        this.setState({
                          fileList: [...this.state.fileList, file],
                        });
                        return false;
                      }}
                      fileList={this.state.fileList}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                  <div className="text-right">
                    <Form.Item noStyle>
                      <Button type="primary" htmlType="submit">
                        Add
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}
AddResearch.contextType = UserContext;
export default withRouter(AddResearch);
