import React from "react";
import PageHeader from "../../components/pageHeader";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import {
  List,
  Button,
  Row,
  Col,
  Popconfirm,
  Card,
  Form,
  Checkbox,
  Input,
  Spin,
  message,
} from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeEn from "dayjs/locale/en";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class AccessManagement extends React.Component {
  state = {
    roles: {
      loading: false,
      data: [{ id: -1 }],
      active: null,
      mode: null,
    },
    permissions: {
      loading: false,
      all: [],
      activeRolePermissions: [],
    },
  };
  permissionFormRef = React.createRef();
  componentDidMount() {
    if (this.context.user?.permissions.includes("view Role")) {
      this.getRoles();
    } else {
      this.setState({ roles: { ...this.state.roles, data: [{ id: -1 }] } });
    }
    if (this.context.user?.permissions.includes("view Permission")) {
      this.getPermissions();
    }
  }
  getRoles = async () => {
    const api = requests();
    this.setState({ roles: { ...this.state.roles, loading: true } });
    try {
      let response = await api.get(endpoints.get_roles, { limit: -1 }, true);
      this.setState({
        roles: {
          ...this.state.roles,
          loading: false,
          data: [...response?.data?.data, { id: -1 }],
        },
      });
    } catch (e) {
      this.setState({ roles: { ...this.state.roles, loading: false } });
    }
  };
  getPermissions = async () => {
    const api = requests();
    this.setState({
      permissions: { ...this.state.permissions, loading: true },
    });
    try {
      let response = await api.get(endpoints.get_permissions, null, true);
      this.setState({
        permissions: {
          ...this.state.permissions,
          loading: false,
          all: response?.data,
        },
      });
    } catch (e) {
      this.setState({
        permissions: { ...this.state.permissions, loading: false },
      });
    }
  };
  selectActiveRole = async (id, mode = "edit") => {
    const api = requests();
    try {
      this.setState({
        roles: { ...this.state.roles, loading: true },
        permissions: { ...this.state.permissions, loading: true },
      });
      let response = await api.get(endpoints.get_roles + "/" + id, {}, true);
      this.setState(
        {
          permissions: {
            ...this.state.permissions,
            loading: false,
            activeRolePermissions: response.data.permissions.map(
              (permission) => permission.name
            ),
          },
          roles: {
            ...this.state.roles,
            loading: false,
            active: id,
            mode: mode,
          },
        },
        () => {
          this.permissionFormRef?.current.setFieldsValue({
            name: response.data.name,
            permissions: response.data.permissions.map(
              (permission) => permission.name
            ),
          });
        }
      );
    } catch (e) {
      this.setState({
        roles: { ...this.state.roles, loading: false },
        permissions: { ...this.state.permissions, loading: false },
      });
    }
  };
  updateRole = async (values) => {
    const api = requests();
    try {
      this.setState({
        roles: { ...this.state.roles, loading: true },
        permissions: { ...this.state.permissions, loading: true },
      });
      if (this.state.roles.active === -1) {
        const response = await api.post(endpoints.get_roles, values, true);
        this.getRoles();
        this.selectActiveRole(response.data.id);
        message.success(response.message);
      } else {
        const response = await api.put(
          endpoints.get_roles + "/" + this.state.roles.active,
          values,
          true
        );
        this.getRoles();
        this.selectActiveRole(this.state.roles.active);
        message.success(response.message);
      }
    } catch (e) {
      this.setState({
        roles: { ...this.state.roles, loading: false },
        permissions: { ...this.state.permissions, loading: false },
      });
    }
  };
  deleteRole = async (id) => {
    const api = requests();
    try {
      this.setState({
        roles: { ...this.state.roles, loading: true },
        permissions: { ...this.state.permissions, loading: true },
      });
      let response = await api.del(endpoints.get_roles + "/" + id);
      message.success(response.message);
      this.setState({
        roles: {
          ...this.state.roles,
          loading: false,
          active:
            this.state.roles.active === id ? null : this.state.roles.active,
        },
        permissions: { ...this.state.permissions, loading: false },
      });
      this.getRoles();
    } catch (e) {
      this.setState({
        roles: { ...this.state.roles, loading: false },
        permissions: { ...this.state.permissions, loading: false },
      });
    }
  };
  render() {
    dayjs.extend(relativeTime).locale(localeEn);

    return (
      <>
        <PageHeader title="Access Control" />
        <Form
          ref={this.permissionFormRef}
          onFinish={(values) => {
            this.updateRole(values);
          }}
          layout="vertical"
        >
          <Row gutter={[24, 24]}>
            <Col span={8}>
              {/* <AuthChecker permissions={["view Role"]}> */}
              <List
                loading={this.state.roles.loading}
                itemLayout="horizontal"
                bordered
                dataSource={this.state.roles.data}
                header={<strong>Roles</strong>}
                renderItem={(item) => {
                  if (item.id === -1) {
                    if (this.state.roles.active === -1) {
                      return (
                        <List.Item
                          style={{ background: "#e6f4ff" }}
                          actions={[
                            <Button
                              type="text"
                              size="small"
                              key="0"
                              onClick={() => {
                                this.permissionFormRef?.current?.resetFields();
                                this.setState({
                                  roles: { ...this.state.roles, active: null },
                                });
                              }}
                            >
                              Cancel
                            </Button>,
                          ]}
                        >
                          <div>
                            <div>
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) =>
                                  !["", undefined, null].includes(
                                    getFieldValue("name")
                                  )
                                    ? getFieldValue("name")
                                    : "New Role"
                                }
                              </Form.Item>
                            </div>
                            <div
                              style={{
                                color: "#aaa",
                                fontSize: "0.7em",
                                fontStyle: "italic",
                              }}
                            >
                              Unsaved
                            </div>
                          </div>
                        </List.Item>
                      );
                    }
                    return (
                      <div style={{ padding: 5 }}>
                        <Button
                          type="dashed"
                          block
                          onClick={() => {
                            this.permissionFormRef?.current?.resetFields();
                            this.setState({
                              roles: {
                                ...this.state.roles,
                                active: -1,
                                mode: "edit",
                              },
                            });
                          }}
                        >
                          Add a Role
                        </Button>
                      </div>
                    );
                  }
                  return (
                    <List.Item
                      style={
                        item.id === this.state.roles.active
                          ? { background: "#e6f4ff" }
                          : {}
                      }
                      actions={[
                        <AuthChecker permissions={["view Role"]}>
                          <Button
                            type="text"
                            size="small"
                            key="0"
                            onClick={() =>
                              this.selectActiveRole(item.id, "view")
                            }
                          >
                            View
                          </Button>
                        </AuthChecker>,
                        <AuthChecker permissions={["update Role"]}>
                          <Button
                            type="text"
                            size="small"
                            key="0"
                            onClick={() => this.selectActiveRole(item.id)}
                          >
                            Edit
                          </Button>
                        </AuthChecker>,
                        <AuthChecker permissions={["delete Role"]}>
                          <Popconfirm
                            key="1"
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                              this.deleteRole(item.id);
                            }}
                          >
                            <Button danger type="text" size="small">
                              Delete
                            </Button>
                          </Popconfirm>
                        </AuthChecker>,
                      ]}
                    >
                      <div>
                        <div>{item.name}</div>
                        <div
                          style={{
                            color: "#aaa",
                            fontSize: "0.7em",
                            fontStyle: "italic",
                          }}
                        >
                          Updated {dayjs(item.updated_at).fromNow()}
                        </div>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </Col>
            <Col span={16}>
              {this.state.roles.active !== null ? (
                <Spin spinning={this.state.permissions.loading}>
                  <Form.Item
                    name="name"
                    label="Role Title"
                    rules={[{ required: true }]}
                  >
                    <Input disabled={this.state.roles.mode === "view"} />
                  </Form.Item>
                  <Card title="Permissions">
                    <Form.Item name="permissions" noStyle>
                      <Checkbox.Group>
                        <div>
                          {Object.keys(this.state.permissions.all).map(
                            (groupName, index) => {
                              return (
                                <Row
                                  gutter={[8, 8]}
                                  key={index}
                                  style={{ marginBottom: 48 }}
                                >
                                  <Col span={24}>
                                    <strong>{groupName}</strong>
                                  </Col>
                                  {this.state.permissions.all[groupName].map(
                                    (permission, i) => {
                                      return (
                                        <Col span={8} key={i}>
                                          <Checkbox
                                            value={permission.name}
                                            disabled={
                                              this.state.roles.mode === "view"
                                            }
                                          >
                                            {permission.name}
                                          </Checkbox>
                                        </Col>
                                      );
                                    }
                                  )}
                                </Row>
                              );
                            }
                          )}
                        </div>
                      </Checkbox.Group>
                    </Form.Item>
                    {this.state.roles.mode === "view" ? null : (
                      <div className="text-right">
                        <Form.Item noStyle>
                          <Button type="primary" htmlType="submit">
                            {this.state.roles.active === -1
                              ? "Create Role"
                              : "Update Role"}
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Card>
                </Spin>
              ) : null}
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

AccessManagement.contextType = UserContext;

export default AccessManagement;
