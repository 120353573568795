import React from "react";
import PageHeader from "../../components/pageHeader";
import {
  Row,
  Col,
  Skeleton,
  Form,
  Input,
  Select,
  Button,
  message,
  Spin,
} from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import routes from "../../constants/routes";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class AddUser extends React.Component {
  state = {
    mode: "add",
    loading: false,
    roles: {
      loading: false,
      data: [],
    },
    user: {
      loading: false,
      data: {},
    },
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.setState({ mode: "edit" });
      if (this.context.user?.permissions.includes("update User")) {
        this.getUser();
      }
    } else {
      this.setState({ mode: "add" });
    }
    if (this.context.user?.permissions.includes("view Role")) {
      this.getRolesDropdown();
    }
  }
  formRef = React.createRef();
  getUser = async () => {
    let api = requests();
    this.setState({
      loading: true,
      user: { ...this.state.user, loading: true },
    });
    try {
      let response = await api.get(
        endpoints.get_user + "/" + this.props.params.id,
        {},
        true
      );
      this.setState({
        loading: false,
        user: {
          ...this.state.user,
          data: response.data,
          loading: false,
        },
      });
      this.formRef?.current?.setFieldsValue({
        name: response?.data?.name,
        email: response?.data?.email,
        roles: response?.data?.roles,
      });
    } catch (e) {
      this.setState({
        user: { ...this.state.user, loading: false },
        loading: false,
      });
    }
  };

  getRolesDropdown = async () => {
    let api = requests();
    this.setState({ roles: { ...this.state.roles, loading: true } });
    try {
      let response = await api.get(endpoints.get_roles, { limit: -1 }, true);
      this.setState({
        roles: {
          ...this.state.roles,
          loading: false,
          data: response?.data?.data,
        },
      });
    } catch (e) {
      this.setState({ roles: { ...this.state.roles, loading: false } });
    }
  };
  handleSubmit = async (values) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      if (this.state.mode === "add") {
        let response = await api.post(endpoints.add_user, values, true);
        message.success(response.message);
        this.props.navigate(routes.USERS.path);
      } else if (this.state.mode === "edit") {
        let response = await api.put(
          endpoints.update_user + "/" + this.props.params.id,
          values,
          true
        );
        message.success(response.message);
        this.props.navigate(routes.USERS.path);
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <>
        <AuthChecker
          permissions={
            this.state.mode === "loading"
              ? ["create User", "update User"]
              : this.state.mode === "edit"
              ? ["update User"]
              : ["create User"]
          }
          redirectTo={-1}
        >
          <Spin spinning={this.state.loading}>
            <Row>
              <Col span={8} offset={8}>
                <PageHeader
                  title={
                    this.state.mode === "loading" ? (
                      <Skeleton.Input active={true} />
                    ) : this.state.mode === "edit" ? (
                      <div className="d-flex align-items-center">
                        <Button
                          type="text"
                          size="small"
                          style={{ width: 35, height: 35, marginRight: 5 }}
                          onClick={() => this.props.navigate(-1)}
                        >
                          <i
                            className="ri-arrow-left-line"
                            style={{ fontSize: 20, marginRight: 0 }}
                          ></i>
                        </Button>
                        Edit a user
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Button
                          type="text"
                          size="small"
                          style={{ width: 35, height: 35, marginRight: 5 }}
                          onClick={() => this.props.navigate(-1)}
                        >
                          <i
                            className="ri-arrow-left-line"
                            style={{ fontSize: 20, marginRight: 0 }}
                          ></i>
                        </Button>
                        Add a user
                      </div>
                    )
                  }
                />
                <Form
                  layout="vertical"
                  onFinish={this.handleSubmit}
                  ref={this.formRef}
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true }, { type: "email" }]}
                  >
                    <Input />
                  </Form.Item>
                  {this.state.mode === "add" ? (
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[{ required: true }]}
                    >
                      <Input.Password />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="roles"
                    label="Role"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={this.state.roles.data.map((role) => {
                        return {
                          label: role.name,
                          value: role.name,
                        };
                      })}
                      loading={this.state.roles.loading}
                    />
                  </Form.Item>
                  <div className="text-right">
                    <Form.Item noStyle>
                      {this.state.mode === "loading" ? (
                        <Skeleton.Button />
                      ) : this.state.mode === "edit" ? (
                        <Button type="primary" htmlType="submit">
                          Update User
                        </Button>
                      ) : (
                        <Button type="primary" htmlType="submit">
                          Add User
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}
AddUser.contextType = UserContext;
export default withRouter(AddUser);
