import React from "react";
import PageHeader from "../../components/pageHeader";
import {
  Button,
  Row,
  Col,
  Input,
  Table,
  Space,
  Tag,
  Popconfirm,
  message,
} from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class ProductResearch extends React.Component {
  state = {
    productResearch: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      vendor_code: null,
      vendor_name: null,
    },
    destinationCSV: null,
  };

  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getProductResearch = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch, loading: true },
    });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(
        endpoints.product_research_list,
        dataToSend
      );
      if (response.status === "Success") {
        this.setState({
          productResearch: {
            ...this.state.productResearch,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        productResearch: {
          ...this.state.productResearch,
          loading: false,
          data: [],
        },
      });
    }
  };

  exportCSV = async (id) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch, loading: true },
    });
    try {
      // let dataToSend = this.generateApiParameters();
      const response = await api.get(
        endpoints.product_download_csv + "/" + id
      );
      downloadCSV(
        response,
        ["productResearch", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        productResearch: { ...this.state.productResearch, loading: false },
      });
    }
  };

  exportDestinationCSV = async (id) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch, loading: true },
    });
    try {
      const response = await api.get(
        endpoints.product_destination_csv + "/" + id
      );
      downloadCSV(
        response,
        ["productResearch", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        productResearch: { ...this.state.productResearch, loading: false },
      });
    }
  };

  deleteResearch = async (id) => {
    const api = requests();
    this.setState({
      productResearch: { ...this.state.productResearch, loading: true },
    });
    try {
      const response = await api.del(
        endpoints.delete_research + "/" + id,
        {},
        true
      );
      message.success(response.message);
      this.setState({
        productResearch: { ...this.state.productResearch, loading: false },
      });
      this.getProductResearch();
    } catch (e) {
      this.setState({
        productResearch: { ...this.state.productResearch, loading: false },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view ProductResearch")) {
      this.getProductResearch();
    }
  }

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 2,
        },
        render: (status) => (<Tag color="green">{status}</Tag>),
      },
      {
        title: "Download Source CSV",
        dataIndex: "downloadsource",
        key: "downloadsource",
        render: (text, record) => {
          if (record.source_data === true) {
            return (
              <Button
                icon={<i className="ri-download-cloud-2-line"></i>}
                loading={this.state.productResearch.loading}
                onClick={() => this.exportCSV(record.id)}
              >
                Get Source CSV
              </Button>
            );
          } else {
            return <small>No Source CSV available</small>;
          }
        },
      },
      {
        title: "Download Destination CSV",
        dataIndex: "destination_data",
        key: "destination_data",
        render: (text, record) => {
          if (record.destination_data === true) {
            return (
              <Button
                icon={<i className="ri-download-cloud-2-line"></i>}
                loading={this.state.productResearch.loading}
                onClick={() => this.exportDestinationCSV(record.id)}
              >
                Get Destination CSV
              </Button>
            );
          } else {
            return <small>No Destination CSV available</small>;
          }
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              <Link
                to={routes.SHOW_PRODUCT_RESEARCH.path.replace(":id", record.id)}
              >
                <Button>View</Button>
              </Link>

              <AuthChecker permissions={["delete ProductResearch"]}>
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.deleteResearch(record.id);
                  }}
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </AuthChecker>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <PageHeader
          title="Product Research"
          actions={[
            <AuthChecker permissions={["view ProductResearch"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getProductResearch();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getProductResearch();
                    }
                  }}
                  disabled={this.state.productResearch.loading}
                />
                <Button
                  onClick={() => this.getProductResearch()}
                  loading={this.state.productResearch.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            <AuthChecker permissions={["create ProductResearch"]}>
              {/* <Link to={routes.ADD_USER.path}>
                <Button type="primary" icon={<i className="ri-add-line"></i>}>
                  Add a user
                </Button>
              </Link> */}

              <Link to={routes.ADD_PRODUCT_RESEARCH.path}>
                <Button type="primary" icon={<i className="ri-add-line"></i>}>
                  Add a product research
                </Button>
              </Link>
            </AuthChecker>,
            // <Button
            //   icon={<i className="ri-download-cloud-2-line"></i>}
            //   loading={this.state.productResearch.loading}
            //   onClick={() => this.exportUsers()}
            // >
            //   Export
            // </Button>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName].join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getProductResearch()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.vendorCodeFilter?.current?.reset();
                        this.vendorNameFilter?.current?.reset();
                        this.setState({ filters: modifiedObj }, () =>
                          this.getProductResearch()
                        );
                      }}
                      loading={this.state.productResearch.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view ProductResearch"]}>
              <Table
                columns={columns}
                loading={this.state.productResearch.loading}
                dataSource={this.state.productResearch.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                          .filter((col) => col.order !== undefined)
                          .map((sortItem) => {
                            return {
                              column: sortItem.field,
                              order: sortItem.order,
                            };
                          })
                        : Object.keys(sorter).length > 0
                          ? sorter.order !== undefined
                            ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                            : []
                          : [],
                      filters: filters,
                    },
                    () => this.getProductResearch()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
      </>
    );
  }
}

ProductResearch.contextType = UserContext;
export default ProductResearch;
