import { Checkbox, Skeleton } from "antd";
import React from "react";
import requests from "../../../../utilities/api";
import endpoints from "../../../../constants/endpoints";
import { Row, Col, Form, Button } from "antd";
import ReactCountryFlag from "react-country-flag";

export default class MarketPlace extends React.Component {
  state = {
    marketplaceIdentifiers: {
      data: [],
      loading: false,
    },
  };
  getMarketplaceIdentifiers = async () => {
    const api = requests();
    this.setState({
      marketplaceIdentifiers: {
        ...this.state.marketplaceIdentifiers,
        loading: true,
      },
    });
    let response = await api.get(
      endpoints.marketplace_identifiers_list,
      {},
      true
    );
    if (response.status === "Success") {
      this.setState({
        marketplaceIdentifiers: {
          ...this.state.marketplaceIdentifiers,
          data: response.data,
          loading: false,
        },
      });
    } else {
      this.setState({
        marketplaceIdentifiers: {
          ...this.state.marketplaceIdentifiers,
          loading: false,
        },
      });
    }
  };
  componentDidMount() {
    this.getMarketplaceIdentifiers();
  }
  render() {
    return (
      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item
            label="Marketplaces"
            name="marketplace_identifier_ids"
          >
            <Checkbox.Group
              className="card-checkbox"
              disabled={this.props.loading}
              style={{ width: "100%" }}
            >
              <Row gutter={[16, 16]}>
                {this.state.marketplaceIdentifiers.loading === true
                  ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((x, index) => (
                      <Col span={8} key={index}>
                        <Skeleton.Input block active={true} />
                      </Col>
                    ))
                  : this.state.marketplaceIdentifiers.data.map(
                      (marketplace, index) => {
                        return (
                          <Col span={8} key={index}>
                            <Checkbox value={marketplace.id}>
                              <div className="d-flex align-items-center">
                                <ReactCountryFlag
                                  countryCode={
                                    marketplace.country_code === "UK"
                                      ? "gb"
                                      : marketplace.country_code
                                  }
                                  style={{ fontSize: 32, marginRight: 8 }}
                                />
                                <div>
                                  <strong>{marketplace.country}</strong>
                                  <div>
                                    <small className="marketplace-region">
                                      {marketplace.region}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </Checkbox>
                          </Col>
                        );
                      }
                    )}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <Button
              onClick={() => this.props.changeIndex(0)}
              disabled={this.props.loading}
            >
              Back
            </Button>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                // block
                className="mt10"
                loading={this.props.loading}
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  }
}
