import React from "react";
import { Row, Col, Form, Select, Input, Button } from "antd";
import routes from "../../../../constants/routes";
import { Link } from "react-router-dom";

export default class BasicFields extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formValid: true,
      invalidEmails: [],
    };
  }

  isEmailValid = (email) => {
    if (email === '') {
      return true;
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  customEmailValidation = (rule, value, callback) => {
    const invalidEmails = value.filter((tag) => !this.isEmailValid(tag));
    this.setState({ invalidEmails });

    if (invalidEmails.length > 0) {
      const errorMessage = `Invalid email(s): ${invalidEmails.join(', ')}`;
      callback(errorMessage);
    } else {
      callback();
    }
  };

  render() {
    return (
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Enter email address"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder="Enter password"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="seller_partner_id"
            label="Seller Partner ID"
            rules={[{ required: false }]}
          >
            <Input
              placeholder="Enter seller partner id"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="vendor_name"
            label="Vendor Name"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Enter vendor name"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="vendor_code"
            label="Vendor Code"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Enter vendor code"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: "ACTIVE",
                  value: "ACTIVE",
                },
                {
                  label: "IN_ACTIVE",
                  value: "IN_ACTIVE",
                },
              ]}
              placeholder="Select status"
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col>

        {/* <Col span={24}>
          <Form.Item
            name="marketplace_identifier_ids"
            label="Marketplace Identifiers"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              placeholder="Select marketplace identifiers"
              options={this.state.marketplaceIdentifiers.data.map(
                (marketplace) => {
                  return {
                    label: [
                      marketplace.country,
                      marketplace.country_code,
                      marketplace.region,
                    ].join(" - "),
                    value: marketplace.id,
                  };
                }
              )}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={this.state.marketplaceIdentifiers.loading}
              disabled={this.props.loading}
            />
          </Form.Item>
        </Col> */}
        {/* <Col span={24}>
          <Form.Item
            name="reports_type"
            label="Report Types"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Report Types"
              options={Object.keys(reportTypes).map((key) => {
                return {
                  label: reportTypes[key],
                  value: key,
                };
              })}
              loading={this.state.marketplaceIdentifiers.loading}
            />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item name="email_notification" label="Email Notification"
            rules={[
              {
                validator: this.customEmailValidation,
              },
            ]}>
            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              tokenSeparators={[',']}
              showSearch={false}
              showArrow={false}
              dropdownStyle={{ display: 'none' }}  
              placeholder="Enter email notification"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to={routes.VENDORS.path}>
              <Button disabled={this.props.loading}>Cancel</Button>
            </Link>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                // block
                className="mt10"
                disabled={this.props.loading}
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  }
}
