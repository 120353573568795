import { Skeleton } from "antd";
import React from "react";

export default class DetailBlock extends React.Component {
  state = {
    isReadMore: true,
  };

  toggleReadMore = () => {
    const { isReadMore } = this.state;
    this.setState({ isReadMore: !isReadMore });
  };

  render() {
    const { isReadMore } = this.state;
    const { value } = this.props;
    const contentLength = typeof value === "string" ? value.length : 0;
    const shouldShowButton = contentLength > 40;

    const displayValue =
      typeof value === "string" && shouldShowButton && isReadMore
        ? value.slice(0, 40)
        : value;

    return this.props.loading ? (
      <>
        <Skeleton title paragraph={{ rows: 1 }} />
      </>
    ) : (
      <div className="detail-block">
        <label>{this.props.label}</label>
        <span>
          {[null, undefined, ""].includes(value) ? "Not Available" : displayValue}
          {shouldShowButton && (
            <button onClick={this.toggleReadMore} className="read-more-btn">
              {isReadMore ? "...read more" : "...show less"}
            </button>
          )}
        </span>
      </div>
    );
  }
}

DetailBlock.defaultProps = {
  label: "",
  value: "",
};
