import React from "react";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import PageHeader from "../../components/pageHeader";
import { Row, Spin, Col, Alert, Button, Card } from "antd";
import DetailBlock from "../../components/detailBlock";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import moment from "moment";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class ViewShipment extends React.Component {
  state = {
    loading: false,
    shipmentData: null,
  };
  getShipmentData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_shipment, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ loading: false, shipmentData: response.data });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      if (this.context.user?.permissions.includes("view RemovalShipment")) {
        this.getShipmentData(this.props.params.id);
      }
    }
  }
  render() {
    return (
      <>
        <AuthChecker permissions={["view RemovalShipment"]} redirectTo={-1}>
          <PageHeader
            title="Removal Shipment Details"
            actions={[
              <Link to={routes.REMOVAL_SHIPMENTS.path}>
                <Button disabled={this.state.loading}>Cancel</Button>
              </Link>,
            ]}
          />
          <Spin spinning={this.state.loading}>
            <Row gutter={[24, 24]}>
              <Col span={10} className="mt30">
                <Row gutter={[40, 40]}>
                  <Col span={24}>
                    <DetailBlock
                      label="ID"
                      value={this.state.shipmentData?.id}
                      loading={this.state.loading}
                    />
                  </Col>

                  <Col span={8}>
                    <DetailBlock
                      label="Order Id"
                      value={this.state.shipmentData?.order_id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Shipment Date"
                      value={moment(
                        this.state.shipmentData?.shipment_date
                      ).format("DD-MM-YYYY")}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Shipped Quantity"
                      value={this.state.shipmentData?.shipped_quantity}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="SKU"
                      value={this.state.shipmentData?.sku}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="FNSKU"
                      value={this.state.shipmentData?.fnsku}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Carrier"
                      value={this.state.shipmentData?.carrier}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Tracking Number"
                      value={this.state.shipmentData?.tracking_number}
                      loading={this.state.loading}
                    />
                  </Col>

                  <Col span={12}>
                    <DetailBlock
                      label="Removal Order Type"
                      value={this.state.shipmentData?.removal_order_type}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Created At"
                      value={moment(
                        this.state.shipmentData?.created_at
                      ).format("DD-MM-YYYY [T]HH:mm:ss")}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Last Modified At"
                      value={moment(
                        this.state.shipmentData?.last_modified_at
                      ).format("DD-MM-YYYY [T]HH:mm:ss")}
                      loading={this.state.loading}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={10}>
                <Card title=" Vender Detail">
                  <Row gutter={[40, 40]}>
                    <Col span={18}>
                      <DetailBlock
                        label="Vendor Id"
                        value={this.state.shipmentData?.vendor.id}
                        loading={this.state.loading}
                      />
                    </Col>
                    <Col span={6}>
                      <DetailBlock
                        label="Vendor Name"
                        value={this.state.shipmentData?.vendor.vendor_name}
                        loading={this.state.loading}
                      />
                    </Col>
                    <Col span={16}>
                      <DetailBlock
                        label="Seller Partener Id"
                        value={
                          this.state.shipmentData?.vendor.seller_partner_id
                        }
                        loading={this.state.loading}
                      />
                    </Col>
                    <Col span={8}>
                      <DetailBlock
                        label="Vendor Code"
                        value={this.state.shipmentData?.vendor.vendor_code}
                        loading={this.state.loading}
                      />
                    </Col>

                    <Col span={24}>
                      <DetailBlock
                        label="MWS Token"
                        value={this.state.shipmentData?.vendor.mws_token}
                        loading={this.state.loading}
                      />
                    </Col>
                    <Col span={24}>
                      <DetailBlock
                        label="Status"
                        value={
                          this.state.shipmentData?.vendor.status ===
                            "ACTIVE" ? (
                            <Alert
                              message={this.state.shipmentData?.vendor.status}
                              type="success"
                              showIcon
                            />
                          ) : (
                            <Alert
                              message={this.state.shipmentData?.vendor.status}
                              type="error"
                              showIcon
                            />
                          )
                        }
                        loading={this.state.loading}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}
ViewShipment.contextType = UserContext;
export default withRouter(ViewShipment);
