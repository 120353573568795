import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import routes from "./constants/routes";
import "remixicon/fonts/remixicon.css";
import "antd/dist/reset.css";
import withUserContext, { UserContext } from "./contexts/user";
import withSidebar from "./utilities/withSidebar";
import withHelmet from "./utilities/withHelmet";

function App() {
  Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />);
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 8,
          fontSize: 14,
          fontFamily: '"Inter", sans-serif;',
          controlHeight: 44,
          controlPaddingHorizontal: 14,
        },
      }}
    >
      <UserContext.Consumer>
        {(user) => {
          if (user.status === "unknown" || user.status === "loading") {
            return (
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  background: "#fff",
                }}
              ></div>
            );
          }
          return (
            <BrowserRouter basename={process.env.REACT_APP_SUBDIRECTORY}>
              <Routes>
                {Object.values(routes).map((route, index) => {
                  if (user.status === "loggedin") {
                    if (route.allowAccessWhenLoggedIn === false) {
                      return (
                        <Route
                          path={route.path}
                          element={<Navigate to="/" replace={true} />}
                          key={index}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={route.path}
                          Component={withHelmet(
                            route.pageTitle,
                            route.withSidebar
                              ? withSidebar(route.component)
                              : route.component
                          )}
                          key={index}
                        />
                      );
                    }
                  } else if (route.requireLogin === false) {
                    return (
                      <Route
                        path={route.path}
                        Component={withHelmet(
                          route.pageTitle,
                          route.withSidebar
                            ? withSidebar(route.component)
                            : route.component
                        )}
                        key={index}
                      />
                    );
                  } else {
                    return (
                      <Route
                        path={route.path}
                        element={<Navigate to="/login" replace={true} />}
                        key={index}
                      />
                    );
                  }
                })}
              </Routes>
            </BrowserRouter>
          );
        }}
      </UserContext.Consumer>
    </ConfigProvider>
  );
}

export default withUserContext(App);
