import AddVendor from "../pages/vendors/add/add";
import Login from "../pages/login";
import Products from "../pages/products/list";
import RemovalShipments from "../pages/shipments/list";
// import Dashboard from "../pages/dashboard";
import Vendors from "../pages/vendors/list";
import ViewVendor from "../pages/vendors/view";
import ViewProduct from "../pages/products/view";
import viewShipment from "../pages/shipments/view";
import AccessManagement from "../pages/access-management/list";
import Users from "../pages/users/list";
import AddUser from "../pages/users/add";
import PasswordReset from "../pages/password-reset";
import ForgotPassword from "../pages/forgot-password";
import ProductResearch from "../pages/product-research/list";
import ReportRequest from "../pages/report-request/list";
import ViewReport from "../pages/report-request/view";
import ShowResearch from "../pages/product-research/view";
import AddProductResearch from "../pages/product-research/add";

const routes = {
  LOGIN: {
    path: "/login",
    component: Login,
    requireLogin: false,
    allowAccessWhenLoggedIn: false,
    pageTitle: "Login"
  },
  VENDORS: {
    path: "/",
    component: Vendors,
    withSidebar: true,
    pageTitle: "Vendors"
  },
  ADD_VENDOR: {
    path: "/add-vendor",
    component: AddVendor,
    withSidebar: true,
    pageTitle: "Add Vendor"
  },
  EDIT_VENDOR: {
    path: "/edit-vendor/:id",
    component: AddVendor,
    withSidebar: true,
    pageTitle: "Edit Vendor"
  },
  VIEW_VENDOR: {
    path: "/view-vendor/:id",
    component: ViewVendor,
    withSidebar: true,
    pageTitle: "Vendor Details"
  },
  PRODUCTS: {
    path: "/products",
    component: Products,
    withSidebar: true,
    pageTitle: "Products"
  },
  VIEW_PRODUCT: {
    path: "/view-product/:id",
    component: ViewProduct,
    withSidebar: true,
    pageTitle: "Product Details"
  },
  REMOVAL_SHIPMENTS: {
    path: "/removal-shipments",
    component: RemovalShipments,
    withSidebar: true,
    pageTitle: "Removal Shipments"
  },
  VIEW_SHIPMENT: {
    path: "/view-removal-shipment/:id",
    component: viewShipment,
    withSidebar: true,
    pageTitle: "Shipment Details"
  },
  ACCESS_MANAGEMENT: {
    path: "/roles-and-permissions",
    component: AccessManagement,
    withSidebar: true,
    pageTitle: "Access Control"
  },
  USERS: {
    path: "/users",
    component: Users,
    withSidebar: true,
    pageTitle: "Users"
  },
  ADD_USER: {
    path: "/add-user",
    component: AddUser,
    withSidebar: true,
    pageTitle: "Add User"
  },
  EDIT_USER: {
    path: "/edit-user/:id",
    component: AddUser,
    withSidebar: true,
    pageTitle: "Edit User"
  },
  RESET_PASSWORD: {
    path: "/password-reset",
    component: PasswordReset,
    withSidebar: false,
    requireLogin: false,
    pageTitle: "Reset Password"
  },
  FORGOT_PASSWORD: {
    path: "/forgot-password",
    component: ForgotPassword,
    withSidebar: false,
    requireLogin: false,
    pageTitle: "Forgot Password"
  },
  PRODUCT_RESEARCH: {
    path: "/product-research",
    component: ProductResearch,
    withSidebar: true,
    pageTitle: "Product Research"
  },
  REPORT_REQUEST:{
    path:"/report-request",
    component:ReportRequest ,
    withSidebar: true,
    pageTitle:"Report Request"

  },
  VIEW_REPORT:{
    path:"/report-request/show/:id",
    component :ViewReport,
    withSidebar: true,
    pageTitle:"Report Request Details"
  },
  SHOW_PRODUCT_RESEARCH: {
    path: "/view-product-research/:id",
    component: ShowResearch,
    withSidebar: true,
    pageTitle: "Research Details"
  },
  ADD_PRODUCT_RESEARCH: {
    path: "/add-product-research",
    component: AddProductResearch,
    withSidebar: true,
    pageTitle: "Add Product Research"
  },
};

export default routes;
