const reportTypes = {
  GET_AFN_INVENTORY_DATA: {
    description: "FBA Inventory Data Report",
    purpose: "Required FBA Data",
  },
  GET_FBA_FULFILLMENT_CUSTOMER_RETURNS_DATA: {
    description: "LPN Data Report",
    purpose: "Required FBA/Claims Data",
  },
  GET_LEDGER_SUMMARY_VIEW_DATA: {
    description: "Amazon Inventory Adjustments Report",
    purpose: "Required Claims Data",
  },
  GET_LEDGER_DETAIL_VIEW_DATA: {
    description: "Unsellable Inventory Summary Report",
    purpose: "Required Claims Data",
  },
  GET_FBA_FULFILLMENT_REMOVAL_ORDER_DETAIL_DATA: {
    description: "Removal Reconciliation Report",
    purpose: "Required FBA Data",
  },
  GET_FBA_FULFILLMENT_REMOVAL_SHIPMENT_DETAIL_DATA: {
    description: "Removal Shipment Detail Report",
    purpose: "Required FBA Data",
  },
  GET_FBA_RECOMMENDED_REMOVAL_DATA: {
    description: "Removal Planning Report",
    purpose: "Recommended for Planning Large Removals",
  },
  GET_FBA_REIMBURSEMENTS_DATA: {
    description: "Claim Reimbursements Report",
    purpose: "Required Claims Data",
  },
  GET_FLAT_FILE_RETURNS_DATA_BY_RETURN_DATE: {
    description: "B2C Report 60 Days",
    purpose: "Required for B2C Returns",
  },
};

export default reportTypes;
