import { Spin } from "antd";
import Cookies from "js-cookie";
import React from "react";
import endpoints from "../constants/endpoints";
import requests from "../utilities/api";

let UserContext = React.createContext({});

export default function withUserContext(ChildComponent) {
  return class extends React.Component {
    state = {
      user: {
        id: null,
        name: null,
        email: null,
        email_verified_at: null,
        created_at: null,
        updated_at: null,
      },
      status: "unknown",
      fetchUser: async (token) => {
        const api = requests();
        this.setState({ status: "loading" });
        let response = await api.get(
          endpoints.me,
          {},
          true,
          [undefined, null, ""].includes(token)
            ? {}
            : {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
        );
        if ((response.status = "Success")) {
          this.setState(
            {
              user: {
                ...response.data,
                token: token ?? Cookies.get("tradepoint-token"),
              },
            },
            () => {
              this.setState({ status: "loggedin" });
            }
          );
        } else {
          Cookies.remove("tradepoint-token");
          this.setState({ status: "not_loggedin" });
        }
      },
      logout: async () => {
        const api = requests();
        this.setState({ status: "loading" });
        let response = await api.get(endpoints.logout, {}, true);
        if (response.status === "Success") {
          Cookies.remove("tradepoint-token");
          this.setState({ status: "not_loggedin" });
        } else {
          this.setState({ status: "loggedin" });
        }
      },
    };
    componentDidMount() {
      let token = Cookies.get("tradepoint-token");
      if (![undefined, null, ""].includes(token)) {
        this.state.fetchUser();
      } else {
        this.setState({ status: "not_loggedin" });
      }
    }
    render() {
      return (
        <UserContext.Provider value={this.state}>
          <Spin spinning={this.state.status === "loading"}>
            <ChildComponent {...this.props} />
          </Spin>
        </UserContext.Provider>
      );
    }
  };
}

export { UserContext };
