import React from "react";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import PageHeader from "../../components/pageHeader";
import {
  Row,
  Spin,
  Col,
  Alert,
  Button,
  Image,
  Skeleton,
  Tabs,
  Descriptions,
} from "antd";
import DetailBlock from "../../components/detailBlock";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthChecker from "../../components/authChecker";
import { UserContext } from "../../contexts/user";
import notAvailableImage from "../../images/no-image-available.jpg";

class ViewProduct extends React.Component {
  state = {
    loading: false,
    productData: null,
    activeStep: 0,
    showProductInfo: false,
    bulletsPointArray: null,
  };
  getProductData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_product, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ loading: false, productData: response.data });
        if(response.data.bullets_point != null){
          this.setState({ showProductInfo: true, bulletsPointArray: JSON.parse(response.data.bullets_point) });
        }
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      if (this.context.user?.permissions.includes("view Product")) {
        this.getProductData(this.props.params.id);
      }
    }
  }

  fetchBulletsPoints = async () => {
    let api = requests();
    this.setState({ loading: true });
    const identifierForApi = this.state.productData.identifier;
    try {
      let response = await api.get(
        [endpoints.get_bullet_point, identifierForApi].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ bulletsPointArray: response.data, showProductInfo: true, loading: false });
        console.log("Called")
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  render() {
    let highestWidthImage = null;
    let lowestWidthImage = null;
    let pt01Higest = null;
    let pt01Lowest = null;
    let pt02Higest = null;
    let pt02Lowest = null;
    let pt03Higest = null;
    let pt03Lowest = null;
    let pt04Higest = null;
    let pt04Lowest = null;
    let pt05Higest = null;
    let pt05Lowest = null;
    let pt06Higest = null;
    let pt06Lowest = null;

    if (this.state.productData?.images) {
      try {
        const imagesArray = JSON.parse(this.state.productData.images);

        // Check if imagesArray is an array
        if (Array.isArray(imagesArray)) {
          // Filter the images array to get only items with variant === "MAIN" (case-insensitive)
          const mainImages = imagesArray.filter(
            image => image.variant.toLowerCase() === "main"
          );

          const pt01Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt01"
          );

          const pt02Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt02"
          );

          const pt03Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt03"
          );

          const pt04Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt04"
          );

          const pt05Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt05"
          );

          const pt06Images = imagesArray.filter(
            image => image.variant.toLowerCase() === "pt06"
          );

          // Check if mainImages is an array and contains elements
          if (Array.isArray(mainImages) && mainImages.length > 0) {
            highestWidthImage = mainImages.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            lowestWidthImage = mainImages.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No Main Images found.");
          }

          if (Array.isArray(pt01Images) && pt01Images.length > 0) {
            pt01Higest = pt01Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt01Lowest = pt01Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT01 Images found.");
          }

          if (Array.isArray(pt02Images) && pt02Images.length > 0) {
            pt02Higest = pt02Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt02Lowest = pt02Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT02 Images found.");
          }

          if (Array.isArray(pt03Images) && pt03Images.length > 0) {
            pt03Higest = pt03Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt03Lowest = pt03Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT03 Images found.");
          }

          if (Array.isArray(pt04Images) && pt04Images.length > 0) {
            pt04Higest = pt04Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt04Lowest = pt04Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT04 Images found.");
          }

          if (Array.isArray(pt05Images) && pt05Images.length > 0) {
            pt05Higest = pt05Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt05Lowest = pt05Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT05 Images found.");
          }

          if (Array.isArray(pt06Images) && pt06Images.length > 0) {
            pt06Higest = pt06Images.reduce((prev, current) => {
              return prev.width > current.width ? prev : current;
            });
            pt06Lowest = pt06Images.reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
          } else {
            console.log("No PT05 Images found.");
          }

        } else {
          console.log("productData.images is not a valid JSON array.");
        }
      } catch (error) {
        console.error("Error parsing productData.images:", error);
      }
    } else {
      console.log("productData.images is undefined or null.");
    }

    return (
      <>
        <AuthChecker permissions={["view Product"]} redirectTo={-1}>
          <PageHeader
            title="Product Details"
            actions={[
              <Link to={routes.PRODUCTS.path}>
                <Button disabled={this.state.loading}>Cancel</Button>
              </Link>,
            ]}
          />
          <Spin spinning={this.state.loading}>
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <div
                  className={this.state.activeStep === 0 ? "d-block" : "d-none"}
                >
                  {highestWidthImage && highestWidthImage.link ? (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={highestWidthImage.link}
                      fallback={notAvailableImage}
                    />
                  ) : (
                    <img width={400} height={400} src={notAvailableImage}/>
                  )}
                </div>
                <div
                  className={this.state.activeStep === 1 ? "d-block" : "d-none"}
                >
                  {pt01Higest && pt01Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt01Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>
                <div
                  className={this.state.activeStep === 2 ? "d-block" : "d-none"}
                >
                  {pt02Higest && pt02Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt02Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>
                <div
                  className={this.state.activeStep === 3 ? "d-block" : "d-none"}
                >
                  {pt03Higest && pt03Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt03Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>

                <div
                  className={this.state.activeStep === 4 ? "d-block" : "d-none"}
                >
                  {pt04Higest && pt04Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt04Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>

                <div
                  className={this.state.activeStep === 5 ? "d-block" : "d-none"}
                >
                  {pt05Higest && pt05Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt05Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>

                <div
                  className={this.state.activeStep === 6 ? "d-block" : "d-none"}
                >
                  {pt06Higest && pt06Higest.link && (
                    <Image
                      preview={false}
                      width={400}
                      height={400}
                      src={pt06Higest.link}
                      fallback={notAvailableImage}
                    />
                  )}
                </div>

                <Row gutter={[10, 20]} className="mt10">
                  <Col>
                    {lowestWidthImage && lowestWidthImage.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 0 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={lowestWidthImage.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {pt01Lowest && pt01Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 1 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt01Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {" "}
                    {pt02Lowest && pt02Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 2 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt02Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {pt03Lowest && pt03Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 3 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt03Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {pt04Lowest && pt04Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 4 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt04Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {pt05Lowest && pt05Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 5 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt05Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                  <Col>
                    {pt06Lowest && pt06Lowest.link && (
                      <Image
                        onClick={(e) => {
                          this.setState({ activeStep: 6 });
                        }}
                        preview={false}
                        width={50}
                        height={50}
                        src={pt06Lowest.link}
                        fallback={notAvailableImage}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={16}>
                <Row gutter={[32, 32]}>
                  <Col span={12}>
                    <Skeleton loading={this.state.loading}>
                      <span className="pd-status-wrap">
                        {this.state.productData?.status === "OK" ? (
                          <Alert message="OK" type="success" showIcon />
                        ) : (
                          <Alert message="NOT OK" type="error" showIcon />
                        )}
                      </span>
                      <span className="pd-identifier">
                        {this.state.productData?.identifier}
                      </span>
                      <div className="pd-product-name">
                        {this.state.productData?.item_name}
                      </div>
                    </Skeleton>
                  </Col>

                  <Col span={12}>
                    <div className="prdouct-info-btn" >
                      <Button type="primary" onClick={this.fetchBulletsPoints}>
                        Product Information
                      </Button>
                    </div>
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="New Price Amount"
                      value={[
                        this.state.productData?.new_landed_price_amount,
                        this.state.productData?.new_landed_price_currency,
                      ].join(" ")}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Used Price Amount"
                      value={[
                        this.state.productData?.used_landed_price_amount,
                        this.state.productData?.used_landed_price_currency,
                      ].join(" ")}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Color"
                      value={this.state.productData?.color}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Size"
                      value={this.state.productData?.size}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={8}>
                    <DetailBlock
                      label="Style"
                      value={this.state.productData?.style}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          key: "1",
                          label: "Information",
                          children: (
                            <>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item label="Product Type">
                                  {this.state.productData?.product_type}
                                </Descriptions.Item>
                                <Descriptions.Item label="Browse Classification Display ID">
                                  {
                                    this.state.productData
                                      ?.browse_classification_classification_id
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Model No">
                                  {this.state.productData?.model_number}
                                </Descriptions.Item>
                                <Descriptions.Item label="Browse Classification Display Name">
                                  {
                                    this.state.productData
                                      ?.browse_classification_display_name
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Source Type">
                                  {this.state.productData?.source_type}
                                </Descriptions.Item>
                                <Descriptions.Item label="Source Identifier">
                                  {this.state.productData?.source_identifier}
                                </Descriptions.Item>
                              </Descriptions>
                            </>
                          ),
                        },
                        {
                          key: "2",
                          label: "Manufacturer",
                          children: (
                            <>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item
                                  span={2}
                                  label="Manufacturer"
                                >
                                  {this.state.productData?.manufacturer}
                                </Descriptions.Item>
                                <Descriptions.Item label="MarketPlace ID">
                                  {this.state.productData?.marketplace_id}
                                </Descriptions.Item>
                                <Descriptions.Item label="Brand">
                                  {this.state.productData?.brand}
                                </Descriptions.Item>
                              </Descriptions>
                            </>
                          ),
                        },
                        {
                          key: "3",
                          label: "Identifiers",
                          children: (
                            <>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item label="EAN Identifiers">
                                  <ul>
                                    {this.state.productData?.ean_identifier1 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.ean_identifier1
                                        }
                                      </li>
                                    ) : null}
                                    {this.state.productData?.ean_identifier2 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.ean_identifier2
                                        }
                                      </li>
                                    ) : null}
                                    {this.state.productData?.ean_identifier3 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.ean_identifier3
                                        }
                                      </li>
                                    ) : null}
                                  </ul>
                                </Descriptions.Item>
                                <Descriptions.Item label="UPC Identifiers">
                                  <ul>
                                    {this.state.productData?.upc_identifier1 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.upc_identifier1
                                        }
                                      </li>
                                    ) : null}
                                    {this.state.productData?.upc_identifier2 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.upc_identifier2
                                        }
                                      </li>
                                    ) : null}
                                    {this.state.productData?.upc_identifier3 ? (
                                      <li>
                                        {
                                          this.state.productData
                                            ?.upc_identifier3
                                        }
                                      </li>
                                    ) : null}
                                  </ul>
                                </Descriptions.Item>
                                <Descriptions.Item label="ASIN Identifier">
                                  {this.state.productData?.asin_identifier1}
                                </Descriptions.Item>
                              </Descriptions>
                            </>
                          ),
                        },
                        {
                          key: "4",
                          label: "Listing",
                          children: (
                            <>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item label="Relationship Child ASINs">
                                  {
                                    this.state.productData
                                      ?.relationship_child_asins
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Relationship Parent ASINs">
                                  {
                                    this.state.productData
                                      ?.relationship_parent_asins
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Item Classification">
                                  {this.state.productData?.item_classification}
                                </Descriptions.Item>
                                <Descriptions.Item label="Package Quantity">
                                  {this.state.productData?.package_quantity}
                                </Descriptions.Item>
                                <Descriptions.Item label="Part Number">
                                  {this.state.productData?.part_number}
                                </Descriptions.Item>
                                <Descriptions.Item label="Number Of Offer Listing Any">
                                  {
                                    this.state.productData
                                      ?.number_of_offer_listing_any
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Number Of Offer Listing New">
                                  {
                                    this.state.productData
                                      ?.number_of_offer_listing_new
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Number Of Offer Listing Used">
                                  {
                                    this.state.productData
                                      ?.number_of_offer_listing_used
                                  }
                                </Descriptions.Item>
                                <Descriptions.Item label="Stage">
                                  {this.state.productData?.stage}
                                </Descriptions.Item>
                              </Descriptions>
                            </>
                          ),
                        },
                        {
                          key: "5",
                          label: "Dimensions",
                          children: (
                            <>
                              <Descriptions column={2} bordered>
                                <Descriptions.Item label="Height">
                                  {this.state.productData
                                    ?.dimensions_height_value
                                    ? [
                                      parseFloat(
                                        this.state.productData
                                          ?.dimensions_height_value
                                      ).toFixed(2),
                                      this.state.productData
                                        ?.dimensions_height_unit,
                                    ].join(" ")
                                    : null}
                                </Descriptions.Item>
                                <Descriptions.Item label="Width">
                                  {this.state.productData
                                    ?.dimensions_width_value
                                    ? [
                                      parseFloat(
                                        this.state.productData
                                          ?.dimensions_width_value
                                      ).toFixed(2),
                                      this.state.productData
                                        ?.dimensions_width_unit,
                                    ].join(" ")
                                    : null}
                                </Descriptions.Item>
                                <Descriptions.Item label="Length">
                                  {this.state.productData
                                    ?.dimensions_length_value
                                    ? [
                                      parseFloat(
                                        this.state.productData
                                          ?.dimensions_length_value
                                      ).toFixed(2),
                                      this.state.productData
                                        ?.dimensions_length_unit,
                                    ].join(" ")
                                    : null}
                                </Descriptions.Item>
                                <Descriptions.Item label="Weight">
                                  {this.state.productData
                                    ?.dimensions_weight_value
                                    ? [
                                      parseFloat(
                                        this.state.productData
                                          ?.dimensions_weight_value
                                      ).toFixed(2),
                                      this.state.productData
                                        ?.dimensions_weight_unit,
                                    ].join(" ")
                                    : null}
                                </Descriptions.Item>
                              </Descriptions>
                            </>
                          ),
                        },
                        (this.state.showProductInfo ? {
                          key: "6",
                          label: "Product Description",
                          children: (
                            <>
                              <ul>
                                {this.state.bulletsPointArray.map((bullet, index) => (
                                  <li key={index}>{bullet}</li>
                                ))}
                              </ul>
                            </>
                          )
                        } : ''),
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}

ViewProduct.contextType = UserContext;

export default withRouter(ViewProduct);