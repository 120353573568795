import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
// import logo from "../images/logo.gif";
import FullLogo from "../images/full_logo.gif";
import requests from "../utilities/api";
import endpoints from "../constants/endpoints";
import Cookies from "js-cookie";
import { UserContext } from "../contexts/user";
import { Link, useNavigate } from "react-router-dom";
import routes from "../constants/routes";

function Login(props) {
  let api = requests();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let result = await api.post(endpoints.login, {
        email: values.username,
        password: values.password,
      });
      Cookies.set("tradepoint-token", result.data.token);
      api = requests(result.data.token);
      userContext.fetchUser(result.data.token);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (userContext.status === "loggedin") {
      navigate(routes.VENDORS.path);
    }
  }, [userContext, navigate]);

  return (
    <>
      <div className="login-container">
        <div className="login-card">
          <div className="text-center">
            <img src={FullLogo} alt="logo" className="img-fluid"></img>
            <Typography.Title level={3} className="mt30">
              Log in to your account
            </Typography.Title>
            <Typography.Text type="secondary">
              Welcome back! Please enter your details.
            </Typography.Text>
          </div>
          <Form
            layout="vertical"
            className="mt30"
            requiredMark="optional"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your username" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true }]}
            >
              <Input.Password
                placeholder="Enter your password"
                disabled={loading}
              />
            </Form.Item>
            <div className="text-right" style={{ marginBottom: 5 }}>
              <Link to={routes.FORGOT_PASSWORD.path}>Fogot Password ?</Link>
            </div>
            <Form.Item noStyle>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                block
                className="mt10"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
