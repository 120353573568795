import React from "react";
import { Form, Input, Button, Typography, message } from "antd";
// import logo from "../../images/logo.gif";
import withRouter from "../../utilities/withRouter";
import routes from "../../constants/routes";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import Cookies from "js-cookie";
import { UserContext } from "../../contexts/user";

class PasswordReset extends React.Component {
  state = {
    loading: false,
    userEmail: null,
    resetToken: null,
  };
  componentDidMount() {
    let urlParams = new URLSearchParams(this.props.location.search);
    let userEmail = urlParams.get("email");
    let resetToken = urlParams.get("password_reset_tokens");
    if (
      [null, undefined, ""].includes(userEmail) ||
      [null, undefined, ""].includes(resetToken)
    ) {
      message.error("Invalid Username or Token");
      this.props.navigate(routes.LOGIN.path);
    } else {
      this.setState({
        userEmail: userEmail,
        resetToken: resetToken,
      });
    }
  }
  handleSubmit = async (values) => {
    const api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.post(
        endpoints.password_reset,
        {
          password_reset_token: this.state.resetToken,
          password: values.password,
          email: this.state.userEmail,
        },
        true
      );
      message.success(response.message);
      let result = await api.post(endpoints.login, {
        email: this.state.userEmail,
        password: values.password,
      });
      Cookies.set("tradepoint-token", result.data.token);
      this.context.fetchUser(result.data.token);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  componentDidUpdate() {
    if (this.context.status === "loggedin") {
      this.props.navigate(routes.VENDORS.path);
    }
  }
  render() {
    return (
      <>
        <div className="login-container">
          <div className="login-card">
            <div className="text-center">
              {/* <img src={logo} alt="logo" className="img-fluid"></img> */}
              <Typography.Title level={3} className="mt30">
                Reset Password
              </Typography.Title>
              <Typography.Text type="secondary">
                Create or reset your account password
              </Typography.Text>
            </div>
            <Form
              layout="vertical"
              className="mt30"
              requiredMark="optional"
              onFinish={this.handleSubmit}
            >
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true }, { min: 8 }]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Enter your password"
                  disabled={this.state.loading}
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  { min: 8 },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm your password"
                  disabled={this.state.loading}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  loading={this.state.loading}
                  type="primary"
                  htmlType="submit"
                  block
                  className="mt10"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
PasswordReset.contextType = UserContext;
export default withRouter(PasswordReset);
