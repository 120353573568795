import React from "react";
import PageHeader from "../../components/pageHeader";

import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import ColumnSearch from "../../components/columnSearch";
import {
  Input,
  Button,
  Row,
  Col,
  Table,
  Image,
  Typography,
  Space,
  Modal,
  Select,
  Empty,
  Spin,
  Alert,
  Tag
} from "antd";
import AuthChecker from "../../components/authChecker";
import { UserContext } from "../../contexts/user";

class ReportRequest extends React.Component {
  state = {
    reports: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      product_type: null,
      marketplace_id: null,
      brand: null,
      manufacturer: null,
    },
    amazonModalOpen: false,
    amazonModal: {
      selectedIdentifier: "asin",
      data: [],
      loading: false,
      searchValue: null,
      isError: false,
      isASINSuccess: false,
    },
  };
  productTypeFilter = React.createRef();
  marketPlaceIdFilter = React.createRef();
  brandFilter = React.createRef();
  manufacturerFilter = React.createRef();
  identifierFilter = React.createRef();
  itemNameFilter = React.createRef();
  ;
  orderDateFilter = React.createRef();

  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (columnName === "created_at" && this.state.filters[columnName] !== null) {
        dataToSend = {
          ...dataToSend,
          created_at_date_start:
            this.state.filters[columnName][0]?.format("YYYY-MM-DD"),
            created_at_date_end:
            this.state.filters[columnName][1]?.format("YYYY-MM-DD"),
        };
      } else if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getReports = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({ reports: { ...this.state.reports, loading: true } });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(endpoints.report_request_list, dataToSend);
      if (response.status === "Success") {
        this.setState({
          reports: {
            ...this.state.reports,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
      }
    } catch (e) {
      this.setState({
        reports: { ...this.state.reports, loading: false, data: [] },
      });
    }
  };

  //   exportProducts = async () => {
  //     const api = requests();
  //     this.setState({ products: { ...this.state.products, loading: true } });
  //     try {
  //       let dataToSend = this.generateApiParameters();
  //       const response = await api.get(endpoints.export_product, dataToSend);
  //       downloadCSV(
  //         response,
  //         ["products", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
  //       );
  //     } catch (e) {
  //     } finally {
  //       this.setState({
  //         products: { ...this.state.products, loading: false },
  //       });
  //     }
  //   };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view Product")) {
      this.getReports();
    }
  }

  searchOnAmazon = async () => {
    this.setState(
      {
        amazonModal: {
          ...this.state.amazonModal,
          loading: true,
          isError: false,
          isASINSuccess: false,
        },
      },
      async () => {
        try {
          const api = requests();
          const response = await api.get(
            "/search-product/" +
            this.state.amazonModal.searchValue +
            "/" +
            this.state.amazonModal.selectedIdentifier.toUpperCase()
          );
          let result = response?.data;
          this.setState({
            amazonModal: {
              ...this.state.amazonModal,
              data: result,
              loading: false,
            },
          });
          // let result = [
          //   {
          //     asin: "B00006B7P6",
          //     images: [
          //       "https://m.media-amazon.com/images/I/51AO2i1JPwL.jpg",
          //       "https://m.media-amazon.com/images/I/51AO2i1JPwL._SL75_.jpg",
          //       "https://m.media-amazon.com/images/I/916an+Y98OL.jpg",
          //     ],
          //   },
          //   {
          //     asin: "B0752VNRSF",
          //     images: [
          //       "https://m.media-amazon.com/images/I/41L+6kOvzzL.jpg",
          //       "https://m.media-amazon.com/images/I/41L+6kOvzzL._SL75_.jpg",
          //       "https://m.media-amazon.com/images/I/51Yqe27n8uL.jpg",
          //     ],
          //   },
          // ];
          // setTimeout(() => {
          //   this.setState({
          //     amazonModal: {
          //       ...this.state.amazonModal,
          //       data: result,
          //       loading: false,
          //     },
          //   });
          // }, 1000);
        } catch (e) {
          this.setState({
            amazonModal: {
              ...this.state.amazonModal,
              isError: true,
              loading: false,
            },
          });
        }
      }
    );
  };

  fetchProdcut = async (asin) => {
    this.setState({
      amazonModal: { ...this.state.amazonModal, loading: true, isError: false },
    });
    try {
      const api = requests();
      await api.get(
        "/fetch-product/" +
        this.state.amazonModal.searchValue +
        "/" +
        this.state.amazonModal.selectedIdentifier.toUpperCase() +
        "/" +
        asin
      );

      this.setState({
        amazonModal: {
          ...this.state.amazonModal,
          loading: false,
          isASINSuccess: true,
        },
      });
    } catch (e) {
      this.setState({
        amazonModal: {
          ...this.state.amazonModal,
          isError: true,
          loading: false,
        },
      });
    }
  };

  render() {
    const columns = [

      {
        title: "Vendor Code",
        dataIndex: "vendor.vendor_code",
        key: "vendor_code",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 2,
        },
        filteredValue: this.state.filters.vendor_code || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.identifierFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (_, record) => record?.vendor?.vendor_code,
      },
      {
        title: "Report Id",
        key: "report_id",
        dataIndex: "report_id",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 3,
        },
        filteredValue: this.state.filters.report_id || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.productTypeFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 150 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Marketplace",
        key: "marketplace_id",
        dataIndex: "marketplace_id",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 4,
        },
        filteredValue: this.state.filters.marketplace_id || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.marketPlaceIdFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Report Type",
        key: "report_type",
        dataIndex: "report_type",
        showSorterTooltip: false,
        sorter: {
          compare: () => true,
          multiple: 5,
        },
        filteredValue: this.state.filters.report_type || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.BrandFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
        render: (text) => {
          return (
            <Typography.Text
              style={{ width: 100 }}
              ellipsis={{ tooltip: text }}
            >
              {text}
            </Typography.Text>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        showSorterTooltip: false,
        filterMultiple: false,
        filteredValue: this.state.filters.status || null,
        filters: [
          {
            text: "Done",
            value: "DONE",
          },
          {
            text: "Processed",
            value: "PROCESSED",
          },
          {
            text: "Fatal",
            value: "FATAL",
          },
          {
            text: "Canacelled",
            value: "CANCELLED",
          },
          {
            text: "In Progress",
            value: "IN_PROGRESS"
          },
          {
            text: "In Queue",
            value: "IN_QUEUE"
          },
          {
            text: "Init",
            value: "INIT"

          }, {
            text: "Processed_Error",
            value: "PROCESSED_ERROR"
          }
        ],
        // sorter: {
        //   compare: () => true,
        //   multiple: 2,
        // },
        sorter: true,
        render: (text) => {
          let tagColor;

          switch (text) {
            case "DONE":
              tagColor = "green";
              break;
            case "PROCESSED":
              tagColor = "green";
              break;
            case "FATAL":
              tagColor = "red";
              break;
            case "INIT":
              tagColor = "green";
              break;
            case "PROCESSED_ERROR":
              tagColor = "red";
              break;
            case "CANCELLED":
              tagColor = "red";
              break;

            default:
              tagColor = "blue";
              break;
          }

          return <Tag color={tagColor}>{text}</Tag>;
        },
      },
      {
        title: "Created At",
        key: "created_at",
        dataIndex: "created_at",
        // render: (shipment_date) => moment(shipment_date).format("DD-MM-YYYY"),
        // sorter: {
        //   compare: () => true,
        //   multiple: 2,
        // },
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.created_at || null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.orderDateFilter}
              type="dateRange"
              placement="bottomRight"
              onSearch={(value) => {
                setSelectedKeys(value ? value : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              <AuthChecker permissions={["view Vendor"]}>
                <Link to={routes.VIEW_REPORT.path.replace(":id", record.id)}>
                  <Button>View</Button>
                </Link>
              </AuthChecker>

            </Space>
          );
        },
      },
      //   {
      //     title: "Created At",
      //     key: "created_at",
      //     dataIndex: "created_at",
      //     showSorterTooltip: false,
      //     sorter: {
      //       compare: () => true,
      //       multiple: 6,
      //     },
      //     filteredValue: this.state.filters.manufacturer || null,
      //     filterDropdown: ({
      //       setSelectedKeys,
      //       selectedKeys,
      //       confirm,
      //       clearFilters,
      //       close,
      //     }) => {
      //       return (
      //         <ColumnSearch
      //           ref={this.ManufacturerFilter}
      //           onSearch={(value) => {
      //             setSelectedKeys(value ? [value] : []);
      //             confirm();
      //           }}
      //         />
      //       );
      //     },
      //     render: (text) => {
      //       return (
      //         <Typography.Text
      //           style={{ width: 100 }}
      //           ellipsis={{ tooltip: text }}
      //         >
      //           {text}
      //         </Typography.Text>
      //       );
      //     },
      //   },
      //   {
      //     title: "Model No",
      //     key: "model_number",
      //     dataIndex: "model_number",
      //     showSorterTooltip: false,
      //     sorter: {
      //       compare: () => true,
      //       multiple: 7,
      //     },
      //     filteredValue: this.state.filters.model_number || null,
      //     filterDropdown: ({
      //       setSelectedKeys,
      //       selectedKeys,
      //       confirm,
      //       clearFilters,
      //       close,
      //     }) => {
      //       return (
      //         <ColumnSearch
      //           ref={this.ManufacturerFilter}
      //           onSearch={(value) => {
      //             setSelectedKeys(value ? [value] : []);
      //             confirm();
      //           }}
      //         />
      //       );
      //     },
      //   },
      //   {
      //     title: "Source Type",
      //     key: "source_type",
      //     dataIndex: "source_type",
      //     showSorterTooltip: true,
      //     sorter: {
      //       compare: () => true,
      //       multiple: 6,
      //     },
      //     filteredValue: this.state.filters.source_type || null,
      //     filterDropdown: ({
      //       setSelectedKeys,
      //       selectedKeys,
      //       confirm,
      //       clearFilters,
      //       close,
      //     }) => {
      //       return (
      //         <ColumnSearch
      //           ref={this.ManufacturerFilter}
      //           onSearch={(value) => {
      //             setSelectedKeys(value ? [value] : []);
      //             confirm();
      //           }}
      //         />
      //       );
      //     },
      //     render: (text) => {
      //       return (
      //         <Typography.Text
      //           style={{ width: 100 }}
      //           ellipsis={{ tooltip: text }}
      //         >
      //           {text}
      //         </Typography.Text>
      //       );
      //     },
      //   },
      //   {
      //     title: "Source Identifier",
      //     key: "source_identifier",
      //     dataIndex: "source_identifier",
      //     showSorterTooltip: true,
      //     sorter: {
      //       compare: () => true,
      //       multiple: 6,
      //     },
      //     filteredValue: this.state.filters.source_identifier || null,
      //     filterDropdown: ({
      //       setSelectedKeys,
      //       selectedKeys,
      //       confirm,
      //       clearFilters,
      //       close,
      //     }) => {
      //       return (
      //         <ColumnSearch
      //           ref={this.ManufacturerFilter}
      //           onSearch={(value) => {
      //             setSelectedKeys(value ? [value] : []);
      //             confirm();
      //           }}
      //         />
      //       );
      //     },
      //     render: (text) => {
      //       return (
      //         <Typography.Text
      //           style={{ width: 100 }}
      //           ellipsis={{ tooltip: text }}
      //         >
      //           {text}
      //         </Typography.Text>
      //       );
      //     },
      //   },
      //   {
      //     title: "Actions",
      //     dataIndex: "actions",
      //     key: "actions",
      //     width: "150px",
      //     render: (text, record) => {
      //       return (
      //         <Space>
      //           <Link
      //             to={routes.VIEW_PRODUCT.path.replace(":id", record.identifier)}
      //           >
      //             <Button>View</Button>
      //           </Link>
      //           {/* <Link to={routes.EDIT_VENDOR.path.replace(":id", record.id)}>
      //             <Button>Edit</Button>
      //           </Link> */}
      //         </Space>
      //       );
      //     },
      //   },
    ];

    return (
      <>
        <PageHeader
          title="Report Request"
          actions={[
            <AuthChecker permissions={["view Product"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getReports();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getReports();
                    }
                  }}
                  disabled={this.state.reports.loading}
                />
                <Button
                  onClick={() => this.getReports()}
                  loading={this.state.reports.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            // <AuthChecker permissions={["export Product"]}>
            //   <Button
            //     icon={<i className="ri-download-cloud-2-line"></i>}
            //     loading={this.state.reports.loading}
            //     onClick={() => this.exportReports()}
            //   >
            //     Export
            //   </Button>
            // </AuthChecker>,
            // <AuthChecker permissions={["fetch Product"]}>
            //   <Button
            //     onClick={() =>
            //       this.setState({
            //         amazonModalOpen: true,
            //         amazonModal: {
            //           ...this.state.amazonModal,
            //           data: [],
            //           isError: false,
            //           isASINSuccess: false,
            //           searchValue: null,
            //         },
            //       })
            //     }
            //   >
            //     Direct search on{" "}
            //     <img
            //       src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
            //       alt="Amazon"
            //       style={{ height: 20, marginLeft: 5, marginBottom: -10 }}
            //     />
            //   </Button>
            // </AuthChecker>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName]
                                .map((el) =>
                                  columnName === "created_at"
                                    ? el.format("YYYY-MM-DD")
                                    : el
                                )
                                .join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getReports()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        this.itemNameFilter?.current?.reset();
                        this.identifierFilter?.current?.reset();
                        this.productTypeFilter?.current?.reset();
                        this.marketPlaceIdFilter?.current?.reset();
                        this.brandFilter?.current?.reset();
                        this.orderDateFilter?.current?.reset();
                        this.manufacturerFilter?.current?.reset();

                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.setState({ filters: modifiedObj }, () =>
                          this.getReports()
                        );
                      }}
                      loading={this.state.reports.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view Product"]}>
              <Table
                columns={columns}
                loading={this.state.reports.loading}
                dataSource={this.state.reports.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                          .filter((col) => col.order !== undefined)
                          .map((sortItem) => {
                            return {
                              column: sortItem.field,
                              order: sortItem.order,
                            };
                          })
                        : Object.keys(sorter).length > 0
                          ? sorter.order !== undefined
                            ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                            : []
                          : [],
                      filters: filters,
                    },
                    () => this.getReports()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
        <Modal
          title="Direct Search on Amazon"
          open={this.state.amazonModalOpen}
          maskClosable={false}
          onCancel={() => this.setState({ amazonModalOpen: false })}
          cancelButtonProps={{
            disabled: this.state.amazonModal.loading,
          }}
          closable={!this.state.amazonModal.loading}
          onOk={() => this.setState({ amazonModalOpen: false })}
          okButtonProps={{
            disabled: this.state.amazonModal.loading,
          }}
        >
          <Input
            className="amazon-serach-modal-search"
            addonBefore={
              <Select
                value={this.state.amazonModal.selectedIdentifier}
                disabled={this.state.amazonModal.loading}
                onChange={(value) =>
                  this.setState({
                    amazonModal: {
                      ...this.state.amazonModal,
                      selectedIdentifier: value,
                    },
                  })
                }
                options={[
                  {
                    label: "ASIN",
                    value: "asin",
                  },
                  {
                    label: "UPC",
                    value: "upc",
                  },
                  {
                    label: "EAN",
                    value: "ean",
                  },
                ]}
              />
            }
            disabled={this.state.amazonModal.loading}
            addonAfter={
              <Button
                loading={this.state.amazonModal.loading}
                onClick={() => this.searchOnAmazon()}
              >
                Search
              </Button>
            }
            onChange={(e) => {
              this.setState({
                amazonModal: {
                  ...this.state.amazonModal,
                  searchValue: e.target.value ?? null,
                },
              });
            }}
            value={this.state.amazonModal.searchValue}
          />
          <div className="mt30">
            {this.state.amazonModal.loading ? (
              <div className="text-center">
                <Spin spinning={this.state.amazonModal.loading} />
              </div>
            ) : this.state.amazonModal.isError ? (
              <Alert message="No Results Found" type="error" showIcon />
            ) : this.state.amazonModal.data?.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : this.state.amazonModal.isASINSuccess === true ? (
              <Alert
                message="The product has been successfully fetched, it can be searched in the product list after some time"
                type="success"
                showIcon
              />
            ) : (
              <Table
                size="small"
                columns={[
                  {
                    title: "Identifier",
                    key: "identifier",
                    dataIndex: "identifier",
                    render: (value, record, index) => {
                      return (
                        <>
                          <div className="product-image-container">
                            <div className="product-image-wrapper-sm">
                              <Image
                                width={40}
                                height={40}
                                src={record.images[0] ?? null}
                              />
                            </div>
                            <div>
                              <div className="product-title">
                                <Typography.Text
                                  style={{ width: 200 }}
                                  ellipsis={{ tooltip: record.item_name }}
                                >
                                  {record.asin}
                                </Typography.Text>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: "Action",
                    key: "action",
                    dataIndex: "action",
                    align: "right",
                    render: (_, record) => {
                      return (
                        <Button
                          size="small"
                          onClick={() => this.fetchProdcut(record.asin)}
                        >
                          Fetch
                        </Button>
                      );
                    },
                  },
                ]}
                dataSource={this.state.amazonModal.data}
                pagination={false}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

ReportRequest.contextType = UserContext;
export default ReportRequest;
