import React from "react";
import PageHeader from "../../components/pageHeader";
import {
  Button,
  Row,
  Col,
  Input,
  Table,
  Space,
  Tag,
  Popconfirm,
  message,
} from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import { UserContext } from "../../contexts/user";
import AuthChecker from "../../components/authChecker";

class Users extends React.Component {
  state = {
    users: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      vendor_code: null,
      vendor_name: null,
    },
  };

  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getUsers = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({ users: { ...this.state.users, loading: true } });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(endpoints.get_users, dataToSend);
      if (response.status === "Success") {
        this.setState({
          users: {
            ...this.state.users,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
      }
    } catch (e) {
      this.setState({
        users: { ...this.state.users, loading: false, data: [] },
      });
    }
  };

  exportUsers = async () => {
    const api = requests();
    this.setState({ users: { ...this.state.users, loading: true } });
    try {
      let dataToSend = this.generateApiParameters();
      const response = await api.get(endpoints.export_vendor, dataToSend);
      downloadCSV(
        response,
        ["users", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        users: { ...this.state.users, loading: false },
      });
    }
  };

  deleteUser = async (id) => {
    const api = requests();
    this.setState({ users: { ...this.state.users, loading: true } });
    try {
      const response = await api.del(
        endpoints.delete_user + "/" + id,
        {},
        true
      );
      message.success(response.message);
      this.setState({
        users: { ...this.state.users, loading: false },
      });
      this.getUsers();
    } catch (e) {
      this.setState({
        users: { ...this.state.users, loading: false },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view User")) {
      this.getUsers();
    }
  }

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        // showSorterTooltip: false,
        // sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        // showSorterTooltip: false,
        // sorter: true,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (value, record, index) => {
          return record.roles.map((role) => {
            return (
              <div className="table-tag" key={role.name}>
                <Tag>{role.name}</Tag>
              </div>
            );
          });
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              {/* <Link to={routes.VIEW_VENDOR.path.replace(":id", record.id)}>
                <Button>View</Button>
              </Link> */}
              <AuthChecker permissions={["update User"]}>
                <Link to={routes.EDIT_USER.path.replace(":id", record.id)}>
                  <Button>Edit</Button>
                </Link>
              </AuthChecker>
              <AuthChecker permissions={["delete User"]}>
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.deleteUser(record.id);
                  }}
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </AuthChecker>
            </Space>
          );
        },
      },
    ];

    return (
      <>
        <PageHeader
          title="Users"
          actions={[
            <AuthChecker permissions={["view User"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getUsers();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getUsers();
                    }
                  }}
                  disabled={this.state.users.loading}
                />
                <Button
                  onClick={() => this.getUsers()}
                  loading={this.state.users.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            <AuthChecker permissions={["create User"]}>
              <Link to={routes.ADD_USER.path}>
                <Button type="primary" icon={<i className="ri-add-line"></i>}>
                  Add a user
                </Button>
              </Link>
            </AuthChecker>,
            // <Button
            //   icon={<i className="ri-download-cloud-2-line"></i>}
            //   loading={this.state.users.loading}
            //   onClick={() => this.exportUsers()}
            // >
            //   Export
            // </Button>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName].join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getUsers()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.vendorCodeFilter?.current?.reset();
                        this.vendorNameFilter?.current?.reset();
                        this.setState({ filters: modifiedObj }, () =>
                          this.getUsers()
                        );
                      }}
                      loading={this.state.users.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view User"]}>
              <Table
                columns={columns}
                loading={this.state.users.loading}
                dataSource={this.state.users.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                            .filter((col) => col.order !== undefined)
                            .map((sortItem) => {
                              return {
                                column: sortItem.field,
                                order: sortItem.order,
                              };
                            })
                        : Object.keys(sorter).length > 0
                        ? sorter.order !== undefined
                          ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                          : []
                        : [],
                      filters: filters,
                    },
                    () => this.getUsers()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
      </>
    );
  }
}

Users.contextType = UserContext;
export default Users;
