import React from "react";
import PageHeader from "../../components/pageHeader";
import { Button, Row, Col, Input, Table, Space, Tag } from "antd";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import ColumnSearch from "../../components/columnSearch";
import downloadCSV from "../../utilities/downloadCsv";
import moment from "moment";
import AuthChecker from "../../components/authChecker";
import { UserContext } from "../../contexts/user";

class Vendors extends React.Component {
  state = {
    vendors: {
      loading: false,
      data: [],
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showSizeChanger: true,
    },
    search: null,
    sorter: [],
    filters: {
      vendor_code: null,
      vendor_name: null,
      amazon_status: null,
      status: null,
    },
  };

  vendorNameFilter = React.createRef();
  vendorCodeFilter = React.createRef();
  generateApiParameters = ({ limit = undefined, page = undefined } = {}) => {
    let dataToSend = {
      limit: limit ?? this.state.pagination.pageSize,
      page: page ?? this.state.pagination.current,
    };
    if (this.state.sorter.length > 0) {
      let order_by_columns = {};
      this.state.sorter.forEach((value) => {
        order_by_columns = {
          ...order_by_columns,
          [value.column]: value.order === "ascend" ? "ASC" : "DESC",
        };
      });
      dataToSend = {
        ...dataToSend,
        order_by_columns: order_by_columns,
      };
    }
    Object.keys(this.state.filters).forEach((columnName) => {
      if (Array.isArray(this.state.filters[columnName])) {
        dataToSend = {
          ...dataToSend,
          [columnName]: this.state.filters[columnName].join(" "),
        };
      }
    });
    if (![null, undefined, ""].includes(this.state.search)) {
      dataToSend = {
        ...dataToSend,
        search: this.state.search,
      };
    }
    return dataToSend;
  };

  getVendors = async ({ limit, page } = {}) => {
    const api = requests();
    this.setState({ vendors: { ...this.state.vendors, loading: true } });
    try {
      let dataToSend = this.generateApiParameters({ limit, page });
      const response = await api.get(endpoints.vendors_list, dataToSend);
      if (response.status === "Success") {
        this.setState({
          vendors: {
            ...this.state.vendors,
            data: response.data.data,
            loading: false,
          },
          pagination: {
            ...this.state.pagination,
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          },
        });
        console.log(response);
      }
    } catch (e) {
      this.setState({
        vendors: { ...this.state.vendors, loading: false, data: [] },
      });
    }
  };

  exportVendors = async () => {
    const api = requests();
    this.setState({ vendors: { ...this.state.vendors, loading: true } });
    try {
      let dataToSend = this.generateApiParameters();
      const response = await api.get(endpoints.export_vendor, dataToSend);
      downloadCSV(
        response,
        ["vendors", moment().format("YYYY_MM_DD__HH_mm_ss")].join("__")
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        vendors: { ...this.state.vendors, loading: false },
      });
    }
  };

  componentDidMount() {
    if (this.context.user?.permissions.includes("view Vendor")) {
      this.getVendors();
    }
  }

  render() {
    const columns = [
      {
        title: "Seller Partner ID",
        dataIndex: "seller_partner_id",
        key: "seller_partner_id",
        showSorterTooltip: false,
        sorter: true,
      },
      // {
      //   title: "Vendor Name",
      //   dataIndex: "vendor_name",
      //   key: "vendor_name",
      //   showSorterTooltip: false,
      //   sorter: {
      //     compare: () => true,
      //     multiple: 1,
      //   },
      //   filteredValue: this.state.filters.vendor_name || null,
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //     close,
      //   }) => {
      //     return (
      //       <ColumnSearch
      //         ref={this.vendorNameFilter}
      //         onSearch={(value) => {
      //           setSelectedKeys(value ? [value] : []);
      //           confirm();
      //         }}
      //       />
      //     );
      //   },
      // },
      {
        title: "Vendor Code",
        dataIndex: "vendor_code",
        key: "vendor_code",
        showSorterTooltip: false,
        sorter: true,
        filteredValue: this.state.filters.vendor_code || null,
        // onFilter: () => null,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <ColumnSearch
              ref={this.vendorCodeFilter}
              onSearch={(value) => {
                setSelectedKeys(value ? [value] : []);
                confirm();
              }}
            />
          );
        },
      },
      {
        title: "Amazon Status",
        dataIndex: "amazon_status",
        key: "amazon_status",
        showSorterTooltip: false,
        sorter: true,
        filterMultiple: false,
        filteredValue: this.state.filters.amazon_status || null,
        filters: [
          {
            text: "AUTHORIZE_PENDING",
            value: "AUTHORIZE_PENDING",
          },
          {
            text: "AUTHORIZED",
            value: "AUTHORIZED",
          },
          {
            text: "UN_AUTHORIZE",
            value: "UN_AUTHORIZE",
          },
        ],
        render: (text, record, index) => {
          return ["", null, undefined].includes(text) ? (
            ""
          ) : (
            <Tag
              color={
                text === "AUTHORIZE_PENDING"
                  ? "gold"
                  : text === "AUTHORIZED"
                  ? "green"
                  : text === "UN_AUTHORIZE"
                  ? "red"
                  : "default"
              }
            >
              {text}
            </Tag>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        showSorterTooltip: false,
        filterMultiple: false,
        filteredValue: this.state.filters.status || null,
        filters: [
          {
            text: "IN_ACTIVE",
            value: "IN_ACTIVE",
          },
          {
            text: "ACTIVE",
            value: "ACTIVE",
          },
        ],
        // sorter: {
        //   compare: () => true,
        //   multiple: 2,
        // },
        sorter: true,
        render: (text) =>
          text === "ACTIVE" ? (
            <Tag color="green">{text}</Tag>
          ) : (
            <Tag color="red">{text}</Tag>
          ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "150px",
        render: (text, record) => {
          return (
            <Space>
              <AuthChecker permissions={["view Vendor"]}>
                <Link to={routes.VIEW_VENDOR.path.replace(":id", record.id)}>
                  <Button>View</Button>
                </Link>
              </AuthChecker>
              <AuthChecker permissions={["update Vendor"]}>
                <Link to={routes.EDIT_VENDOR.path.replace(":id", record.id)}>
                  <Button>Edit</Button>
                </Link>
              </AuthChecker>
            </Space>
          );
        },
      },
    ];
    return (
      <>
        <PageHeader
          title="Vendors"
          actions={[
            <AuthChecker permissions={["view Vendor"]}>
              <div className="search-wrapper">
                <Input
                  prefix={<i className="ri-search-line"></i>}
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value }, () => {
                      if (e.target.value === "") {
                        this.getVendors();
                      }
                    });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.getVendors();
                    }
                  }}
                  disabled={this.state.vendors.loading}
                />
                <Button
                  onClick={() => this.getVendors()}
                  loading={this.state.vendors.loading}
                >
                  Search
                </Button>
              </div>
            </AuthChecker>,
            <AuthChecker permissions={["create Vendor"]}>
              <Link to={routes.ADD_VENDOR.path}>
                <Button type="primary" icon={<i className="ri-add-line"></i>}>
                  Add a vendor
                </Button>
              </Link>
            </AuthChecker>,
            <AuthChecker permissions={["export Vendor"]}>
              <Button
                icon={<i className="ri-download-cloud-2-line"></i>}
                loading={this.state.vendors.loading}
                onClick={() => this.exportVendors()}
              >
                Export
              </Button>
            </AuthChecker>,
          ]}
        />
        <Row gutter={[0, 24]} align="middle">
          <Col span={24}>
            {Object.keys(this.state.filters).filter(
              (fil) => ![null, undefined, ""].includes(this.state.filters[fil])
            ).length > 0 ? (
              <div className="filters-row">
                <div className="appplied-filter-container">
                  <span>Applied Filters:</span>
                  <span className="applied-filters-list">
                    {Object.keys(this.state.filters)
                      .filter(
                        (fil) =>
                          ![null, undefined, ""].includes(
                            this.state.filters[fil]
                          )
                      )
                      .map((columnName) => {
                        return (
                          <div className="applied-filter-wrap">
                            <div className="applied-filter">
                              <span>
                                {
                                  columns.filter(
                                    (column) => column.key === columnName
                                  )[0].title
                                }
                              </span>
                              {this.state.filters[columnName].join(" ")}
                            </div>
                            <div
                              className="applied-filter-remove"
                              onClick={() => {
                                this.setState(
                                  {
                                    filters: {
                                      ...this.state.filters,
                                      [columnName]: null,
                                    },
                                  },
                                  () => this.getVendors()
                                );
                              }}
                            >
                              <i className="ri-close-line"></i>
                            </div>
                          </div>
                        );
                      })}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        let modifiedObj = {};
                        Object.keys(this.state.filters).map(
                          (col) => (modifiedObj[col] = null)
                        );
                        this.vendorCodeFilter?.current?.reset();
                        this.vendorNameFilter?.current?.reset();
                        this.setState({ filters: modifiedObj }, () =>
                          this.getVendors()
                        );
                      }}
                      loading={this.state.vendors.loading}
                    >
                      Clear All
                    </Button>
                  </span>
                </div>
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            <AuthChecker permissions={["view Vendor"]}>
              <Table
                columns={columns}
                loading={this.state.vendors.loading}
                dataSource={this.state.vendors.data}
                onChange={(pagination, filters, sorter, extra) => {
                  this.setState(
                    {
                      pagination: {
                        ...this.state.pagination,
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                      },
                      sorter: Array.isArray(sorter)
                        ? sorter
                            .filter((col) => col.order !== undefined)
                            .map((sortItem) => {
                              return {
                                column: sortItem.field,
                                order: sortItem.order,
                              };
                            })
                        : Object.keys(sorter).length > 0
                        ? sorter.order !== undefined
                          ? [
                              {
                                column: sorter.field,
                                order: sorter.order,
                              },
                            ]
                          : []
                        : [],
                      filters: filters,
                    },
                    () => this.getVendors()
                  );
                }}
                pagination={this.state.pagination}
                rowKey="id"
              />
            </AuthChecker>
          </Col>
        </Row>
      </>
    );
  }
}

Vendors.contextType = UserContext;

export default Vendors;
