import React from "react";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
import withRouter from "../../utilities/withRouter";
import PageHeader from "../../components/pageHeader";
import { Row, Spin, Col, Alert, Button } from "antd";
import DetailBlock from "../../components/detailBlock";
import ReactCountryFlag from "react-country-flag";
import reportTypes from "../../constants/reports";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import AuthChecker from "../../components/authChecker";

class ViewReport extends React.Component {
  state = {
    loading: false,
    reportData: null,
  };
  getReportData = async (id) => {
    let api = requests();
    this.setState({ loading: true });
    try {
      let response = await api.get(
        [endpoints.get_report, id].join("/"),
        {},
        true
      );
      if (response.status === "Success") {
        this.setState({ loading: false, reportData: response.data });
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    if (this.props.params.id !== undefined) {
      this.getReportData(this.props.params.id);
    } else {
    }
  }

  render() {
    return (
      <>
        <AuthChecker permissions={["view Vendor"]} redirectTo={-1}>
          <PageHeader
            title="Report Request Details"
            actions={[
              <AuthChecker permissions={["view Vendor"]}>
                <Link to={routes. REPORT_REQUEST.path}>
                  <Button disabled={this.state.loading}>Cancel</Button>
                </Link>
              </AuthChecker>,
            //   <AuthChecker permissions={["update Vendor"]}>
            //     <Link
            //       to={routes.EDIT_VENDOR.path.replace(
            //         ":id",
            //         this.props.params.id
            //       )}
            //     >
            //       <Button type="primary" disabled={this.state.loading}>
            //         Edit
            //       </Button>
            //     </Link>
            //   </AuthChecker>,
            ]}
          />
          <Spin spinning={this.state.loading}>
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
              <Col span={24}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <DetailBlock
                      label="ID"
                      value={this.state.reportData?.id}
                      loading={this.state.loading}
                    />
                  </Col>
                  {/* <Col span={24}>
                    <DetailBlock
                      label="Email Address"
                      value={this.state.reportData?.email}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={24}>
                    <DetailBlock
                      label="Password"
                      // value={this.state.reportData?.password}
                      value={"********"}
                      loading={this.state.loading}
                    />
                  </Col> */}
                  {/* <Col span={24}>
                  <DetailBlock
                    label="Email Address"
                    value={this.state.reportData?.email}
                    loading={this.state.loading}
                  />
                </Col> */}
                  {/* <Col span={8}>
                  <DetailBlock
                    label="Account Type"
                    value={this.state.reportData?.account_type}
                    loading={this.state.loading}
                  />
                </Col> */}
                  <Col span={12}>
                    <DetailBlock
                      label="Vendor Id"
                      value={this.state.reportData?.vendor_id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Report Id"
                      value={this.state.reportData?.report_id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="MarketPlace Id"
                      value={this.state.reportData?.marketplace_id}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Report Type"
                      value={this.state.reportData?.report_type}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Created At"
                      value={this.state.reportData?.created_at}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Last Modified At"
                      value={this.state.reportData?.last_modified_at}
                      loading={this.state.loading}
                    />
                  </Col>
                  <Col span={12}>
                    <DetailBlock
                      label="Status"
                      value={
                        this.state.reportData?.status === "DONE" || "PROCESSED" ? (
                          <Alert
                            message={this.state.reportData?.status}
                            type="success"
                            showIcon
                          />
                        ) :this.state.reportData?.status === "IN_QUEUE" || "PROCESSED_ERROR" || "FATAL" || " CANCELLED" (
                          <Alert
                            message={this.state.reportData?.status}
                            type="error"
                            showIcon
                          />
                        )
                      }
                      loading={this.state.loading}
                    />
                  </Col>
                  {/* <Col span={12}>
                    <DetailBlock
                      label="MWS Token"
                      value={this.state.reportData?.mws_token}
                      loading={this.state.loading}
                    />
                  </Col> */}
                  
                  {/* <Col span={12}>
                    <DetailBlock
                      label="Amazon Status"
                      value={
                        this.state.reportData?.amazon_status ===
                          "AUTHORIZE_PENDING" ? (
                          <Alert
                            type="warning"
                            showIcon
                            message={this.state.reportData?.amazon_status}
                          />
                        ) : this.state.reportData?.amazon_status ===
                          "AUTHORIZED" ? (
                          <Alert
                            type="success"
                            showIcon
                            message={this.state.reportData?.amazon_status}
                          />
                        ) : this.state.reportData?.amazon_status ===
                          "UN_AUTHORIZE" ? (
                          <Alert
                            type="error"
                            showIcon
                            message={this.state.reportData?.amazon_status}
                          />
                        ) : (
                          ""
                        )
                      }
                      loading={this.state.loading}
                    />
                  </Col> */}
                </Row>
              </Col>
              {/* <Col span={5}>
                <DetailBlock
                  label="Marketplace Identifiers"
                  value={
                    this.state.reportData?.marketplace_identifiers && this.state.reportData.marketplace_identifiers.length > 0 ? (
                      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                        {this.state.reportData.marketplace_identifiers.map((marketplace, index) => {
                          return (
                            <div className="marketplace-detail-card" key={index}>
                              <div className="d-flex align-items-center">
                                <ReactCountryFlag
                                  countryCode={
                                    marketplace.country_code === "UK" ? "gb" : marketplace.country_code
                                  }
                                  style={{ fontSize: 32, marginRight: 8 }}
                                />
                                <div>
                                  <strong>{marketplace.country}</strong>
                                  <div>
                                    <small className="marketplace-region">{marketplace.region}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    ) : (
                      <span>Not Available</span>
                    )
                  }
                  loading={this.state.loading}
                />
              </Col> */}

              {/* <Col span={9}>
                <DetailBlock
                  label="Report Types"
                  value={this.state.reportData?.report_type.map(
                    (report, index) => {
                      return (
                        <div className="report-type-wrapper" key={index}>
                          <div>
                            <strong>
                              {reportTypes[report.report_type].description}
                            </strong>
                          </div>
                          <div>{reportTypes[report.report_type].purpose}</div>
                          <div>
                            <em>
                              <small>{report.report_type}</small>
                            </em>
                          </div>
                        </div>
                      );
                    }
                  )}
                  loading={this.state.loading}
                />
              </Col> */}
            </Row>
          </Spin>
        </AuthChecker>
      </>
    );
  }
}

export default withRouter(ViewReport);
