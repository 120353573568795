import React from "react";

export default function PageHeader({ title = "", actions = [] }) {
  return (
    <div className="page-header-wrapper">
      <div className="page-header-title">{title}</div>
      <div className="page-header-actions">
        {actions.map((element, index) => {
          return (
            <div className="page-header-action" key={index}>
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
}
