import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
// import logo from "../../images/logo.gif";
import requests from "../../utilities/api";
import endpoints from "../../constants/endpoints";
// import Cookies from "js-cookie";
import { UserContext } from "../../contexts/user";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routes";

function ForgotPassword(props) {
  let api = requests();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let result = await api.post(endpoints.forgot_password, {
        email: values.email,
      });
      message.success(result.message);
      navigate(routes.LOGIN.path);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userContext.status === "loggedin") {
      navigate(routes.VENDORS.path);
    }
  }, [userContext, navigate]);
  return (
    <>
      <div className="login-container">
        <div className="login-card">
          <div className="text-center">
            {/* <img src={logo} alt="logo" className="img-fluid"></img> */}
            <Typography.Title level={3} className="mt30">
              Forgot your password ?
            </Typography.Title>
            <Typography.Text type="secondary">
              Enter your email and recieve a link to reset password
            </Typography.Text>
          </div>
          <Form
            layout="vertical"
            className="mt30"
            requiredMark="optional"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="Enter your email address"
                disabled={loading}
              />
            </Form.Item>

            <Form.Item noStyle>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                block
                className="mt10"
              >
                Send Reset Link
              </Button>
            </Form.Item>
            <div className="text-center" style={{ marginTop: 25 }}>
              <Link to={routes.LOGIN.path}>
                <div className="d-flex align-items-center justify-content-center">
                  <i
                    className="ri-arrow-left-line"
                    style={{ marginRight: 5 }}
                  ></i>
                  Back to Login
                </div>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
