import Sidebar from "../components/sidebar";
import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

export default function withSidebar(ChildComponent) {
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <Sidebar />
          <Content>
            <ChildComponent {...this.props} />
          </Content>
        </Layout>
      );
    }
  };
}
