const endpoints = {
  login: "/login",
  me: "/me",
  logout: "/logout",
  vendors_list: "/vendor/list",
  removal_shipment_list: "/removal-shipment/list",
  marketplace_identifiers_list: "/marketplace-identifire/list",
  add_vendor: "/vendor/store",
  get_vendor: "/vendor/show",
  update_vendor: "/vendor/update",
  export_vendor: "/vendor/export",
  products_list: "/product-information/list",
  get_product: "/product-information/show",
  export_shipment: "removal-shipment/export",
  export_product: "/product-information/export",
  get_shipment: "/removal-shipment/show",
  get_roles: "/user/roles",
  get_permissions: "/user/permissions",
  get_users: "/user/list",
  add_user: "/user/store",
  get_user: "/user/show",
  update_user: "/user/update",
  delete_user: "/user/destroy",
  password_reset: "/user/password-reset",
  forgot_password: "/user/password-reset-link",
  product_research_list: "/product-research/list",
  product_download_csv: "/product-research/source-data",
  product_destination_csv: "/product-research/destination-data",
  get_research:"/product-research/show",
  add_research:"/product-research/store",
  delete_research: "/product-research/destroy",
  report_request_list:"/report-request/list",
  get_report:"/report-request/show",
  get_bullet_point:"/product-information-bullet"
};
export default endpoints;
