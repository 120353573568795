import { Row, Col, Input, Button, DatePicker } from "antd";
import React from "react";

export default class ColumnSearch extends React.Component {
  state = {
    inputValue:
      this.props.type === "dateRange" ? [undefined, undefined] : undefined,
    placementValue: this.props.placement,
  };
  reset() {
    this.setState({
      inputValue:
        this.props.type === "dateRange" ? [undefined, undefined] : undefined,
        placementValue: this.props.placement,
    });
  }
  render() {
    return (
      <div className="antd-table-filter-dropdown">
        <Row
          gutter={[10, 10]}
          style={{ width: this.props.type === "dateRange" ? 250 : 180 }}
        >
          <Col span={24}>
            {this.props.type === "dateRange" ? (
              <DatePicker.RangePicker
                size="small"
                placeholder={["Start", "End"]}
                value={this.state.inputValue}
                placement={this.state.placementValue}
                onChange={(values) => {
                  this.setState({ inputValue: values }, () =>
                    this.props.onSearch(values)
                  );
                }}
                format="YYYY-MM-DD"
              />
            ) : (
              <Input
                size="small"
                prefix={<i className="ri-search-line"></i>}
                placeholder={this.props.placeholder ?? "Search"}
                value={this.state.inputValue}
                onChange={(e) => this.setState({ inputValue: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.props.onSearch(e.target.value);
                  }
                }}
              />
            )}
          </Col>
          <Col span={12}>
            <Button
              size="small"
              block
              onClick={() => {
                this.setState({ inputValue: undefined });
                this.props.clear();
                this.props.onSearch(undefined);
              }}
            >
              Clear
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="small"
              type="primary"
              block
              onClick={() => this.props.onSearch(this.state.inputValue)}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

ColumnSearch.defaultProps = {
  placeholder: undefined,
  onSearch: () => null,
  clear: () => null,
};
